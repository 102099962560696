import "./styles/PartnerProfile.scss";
import { useState, useEffect } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { Tabs, Spin } from 'antd';
import moment from 'moment';
import Profile from "./Profile";

function PartnerProfile() {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`)
            .then(resp => {
                setUser({ ...resp.data, ...JSON.parse(localStorage.getItem("user")) });
                setLoading(false);
            })
            .catch(err => {
                console.log(err.response.data.message);
                setLoading(false)
            })
    }, [])

    const items = [
        {
            key: '1',
            label: `Profile`,
            children: <Profile user={user} />,
        },
        {
            key: '2',
            label: `Edit Profile`,
            children: <></>,
        },
        {
            key: '3',
            label: `Analytics`,
            children: <></>,
        },
        {
            key: '4',
            label: `Account Settings`,
            children: <></>,
        },
    ];

    return (
        <div className="partner-profile-container">
            <div className="partner-profile-top" style={{ fontWeight: "600" }}>Partner Profile</div>
            {loading && <Spin />}
            {
                !loading &&
                <>
                    <div className="partner-profile-main">
                        <div className="partner-profile-main-top-main">
                            <div className="partner-profile-main-top">
                                <div className="partner-profile-main-top-left">
                                    <img src="/img/avatar-default.jpg" alt="" />
                                </div>
                                <div className="partner-profile-main-top-right">
                                    <div className="" style={{ fontWeight: "bolder", fontSize: "1.2rem" }}>{user.name}</div>
                                    <div className="">work with us since
                                        <span style={{ fontWeight: "600", color: "#00B050" }}> {moment(user.createdAt).format("MMMM YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="partner-profile-main-bottom">
                                <Tabs defaultActiveKey="1" items={items} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>

    )
}

export default PartnerProfile;
