import { Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { CSVLink } from "react-csv";

function OwnDelivAdmin() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    useEffect(() => {
        const getFulfillData = async () => {
            setLoading(true)
            const resp = await CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/hire-your-fleet?page=${page}`);
            setTotalPages(resp.data.totalResults)
            setData(resp.data.results)
            setLoading(false)
        }
        getFulfillData();
    }, [page, totalPages])

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (_, record) => {
                return (
                    <>
                        {moment(record.createdAt).format("DD/MM/YY")}
                        <br />
                        {moment(record.createdAt).format("h:mm A")}
                    </>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone No.',
            dataIndex: ["phone", "number"],
        },
        {
            title: 'City',
            dataIndex: 'city',
            render: (_, record) => record.city.toString()
        },
        {
            title: 'Company',
            dataIndex: 'company',
        },
        {
            title: 'Website',
            dataIndex: 'website',
            render: (text, record) =>
                <a href={`http://${text}`} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{text}</a>
        },
        {
            title: 'No. of Riders',
            dataIndex: 'numberOfRiders',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
    ]

    const headers = [
        {
            label: 'CreatedAt',
            key: 'createdAt',
        },
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'Phone No.',
            key: "phone.number",
        },
        {
            label: "City",
            key: "city"
        },
        {
            label: 'Company',
            key: 'company',
        },
        {
            label: 'Website',
            key: 'website',
        },
        {
            label: 'No. of Riders',
            key: 'numberOfRiders',
        },
        {
            label: 'Message',
            key: 'message',
        },
    ]

    return (
        <>
            <h2>Own Delivery Fleet</h2>
            <CSVLink filename={"Own_Delivery_Fleet.csv"} data={data} headers={headers}>
                <button id="btn3" style={{ padding: "4px 8px" }}>EXPORT</button>
            </CSVLink>
            <div className="dash-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={{ position: ["topLeft"], pageSize: 10, total: totalPages }}
                    onChange={(e) => setPage(e.current)}
                    rowKey="id" />
            </div>
        </>
    )
}

export default OwnDelivAdmin;