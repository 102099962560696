import FulfillFormComp from "../../components/FulfillFormComp";
import "./styles/Fullfill.css"
import HowSlide from "../../components/HowSlide";
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import ImageComp from "../../components/ImageComp"

function Fulfill() {

    const fulFillArr = [
        {
            title: "Storage",
            desc: "Use Demand-based Storage in multiple Dark stores and enjoy host of Tech-Enabled services that allows you to track each stage of GRN, Put-away, Location and Bins.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_252.png`
        },
        {
            title: "Fullfilment",
            desc: "Unlock record-breaking fulfillment from Order to Dispatch in < 5 mins. Our full-stack tech, trained staff, well placed inventory ensures Pick, Pack, Dispatch is done in Express mode.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_253.png`
        },
        {
            title: "Delivery",
            desc: "Our well-trained and dedicated Delivery team carries out last mile delivery in < 28 Mins. Our Technology keeps the customer well engaged, fully notified and extends the brand to track each activity of our Delivery partners.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_254.png`
        },
        {
            title: "Returns",
            desc: "Express Delivery doesn't allow user sufficient time to discover product elsewhere, that reduces the RTO % to < 3%. Further such RTO/ return inventory is added back into usable inventory in < 33 hrs, that reduces the overall supply chain investment for the Brand",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_255.png`
        },
        {
            title: "Analytics",
            desc: "Our AI based engine notifies Inventory shortages and slow moving SKU’s that enables Brand to effectively plan supply chain requirements. Our Dashboard and API’s send real-time updates on Order, Dispatch and Delivery statuses.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_242.png`
        }
    ]

    return (
        <>
            <Topbar />

            <div className="fullfill">
                <div className="fullfillTop">
                    <div className="fullfillLeft">
                        <div className="fullfillTitle">Most Convenient & Low Cost to Fulfill & Deliver in <span style={{ color: "#00B050" }}>33 Mins</span></div>
                        <div className="fullfillDesc">
                            <div className="fullfillDescTop">
                                Best suited for D2C, Consumer, E-commerce and Lifestyle Brands. Send33 stores and deliver across categories such as Fashion, Beauty, Wellness, Electronics and anything that is sold online.
                            </div>
                            <div className="fullfillDescBottom">
                                <span style={{ fontWeight: "bolder" }}>How it works :</span>
                                <ul>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Integrate</span> with Full-Tech Stack on open API’s </li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Choose</span> Dark Stores across PIN Codes</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Send</span> Demand based Inventory</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Deliver</span> {"in < 33 Mins"}</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Secure</span> {"COD to your Account"}</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Access</span> {"Return inventory in <33 hrs"}</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Pay Less,</span> {"Get More!"}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="fullfillLeftImg">
                            <ImageComp img={"Group_161.png"} maxWidth={"420px"} height={"auto"} />
                        </div>
                    </div>
                    <div className="fullfillright">
                        <FulfillFormComp />
                    </div>
                </div>

                <div className="fullfillCateg">
                    <div className="fullfillCateheader">Top <span style={{ color: "#00B050" }}>Categories</span></div>
                    <div className="fulllfillCateMain">
                        <div className="fullfillCateMainItem">
                            <ImageComp img={"Mask_group99.png"} width={"100%"} maxWidth={"200px"} height={"auto"} />
                            <span className="fullfillCateTitle">Fashion</span>
                        </div>
                        <div className="fullfillCateMainItem">
                            <ImageComp img={"Mask_group98.png"} width={"100%"} maxWidth={"200px"} height={"auto"} />
                            <span className="fullfillCateTitle">Beauty</span>
                        </div>
                        <div className="fullfillCateMainItem">
                            <ImageComp img={"Mask_group97.png"} width={"100%"} maxWidth={"200px"} height={"auto"} />
                            <span className="fullfillCateTitle">Electronics</span>
                        </div>
                        <div className="fullfillCateMainItem">
                            <ImageComp img={"Mask_group96.png"} width={"100%"} maxWidth={"200px"} height={"auto"} />
                            <span className="fullfillCateTitle">Wellness</span>
                        </div>
                    </div>
                </div>

                <div className="fullfillPrioContainer">
                    <div className="fullfillPrio">
                        <div className="fullfillPriHeader">Prioritizing Customer & Brands!</div>
                        <div className="fullfillPrioItems">
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full1.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    Live Delivery in 33 Mins
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full2.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    CSAT Guaranteed 90%+
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full3.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    {"Delivery > 97%+"}
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full4.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    {"Returns < 3%"}
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full5.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    COD Secure 100%
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/full6.png`} alt="" />
                                <div className="fullfillPriItemDesc">
                                    Reduce Cost to 60%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fullfillRateContainer">
                    <div className="fullfillRate">
                        <div className="fullfillRateTop">Reduce your Burn with <span style={{ color: "#00B050" }}>Send33</span></div>
                        <div className="fullfillRateMain">
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate1.png`} alt="" />
                                <span className="ffItemTitle">Storage Cost</span>
                                <span className="ffItemRate">₹40 onwards</span>
                                <span className="ffItemRateBtm">₹0</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate2.png`} alt="" />
                                <span className="ffItemTitle">Inbounding Cost</span>
                                <span className="ffItemRate">₹1 per item</span>
                                <span className="ffItemRateBtm">₹0</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate3.png`} alt="" />
                                <span className="ffItemTitle">COD Charge</span>
                                <span className="ffItemRate">1 % onwards</span>
                                <span className="ffItemRateBtm">₹0</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate4.png`} alt="" />
                                <span className="ffItemTitle">COD Remittance</span>
                                <span className="ffItemRate">15 days</span>
                                <span className="ffItemRateBtm">3 days*</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate5.png`} alt="" />
                                <span className="ffItemTitle">API Integration</span>
                                <span className="ffItemRate">₹20000</span>
                                <span className="ffItemRateBtm">Free</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate6.png`} alt="" />
                                <span className="ffItemTitle">FC + Del Fee</span>
                                <span className="ffItemRate">₹120</span>
                                <span className="ffItemRateBtm">₹70*</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate7.png`} alt="" />
                                <span className="ffItemTitle">Weight</span>
                                <span className="ffItemRate">For 500gms</span>
                                <span className="ffItemRateBtm">For 10 kg</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate8.png`} alt="" />
                                <span className="ffItemTitle">Minimum orders</span>
                                <span className="ffItemRate">6000 orders</span>
                                <span className="ffItemRateBtm">NIL</span>
                            </div>
                            <div className="ffItem">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/frate9.png`} alt="" />
                                <span className="ffItemTitle">Technology</span>
                                <span className="ffItemRate">Broken or partial</span>
                                <span className="ffItemRateBtm">Machine Learning & AI</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fullfillHow">
                    <div className="howHeader">How it <span style={{ color: "#00B050" }}>works?</span></div>
                    <HowSlide howArr={fulFillArr} />
                </div>

            </div >

            <Footer />
        </>
    )
}

export default Fulfill;