const AWS = require('aws-sdk');

const uploadFile = (fileContent, filetype, filename, fileExt) => {
    const s3 = new AWS.S3({
        accessKeyId: `${process.env.REACT_APP_S3_ACCESS_KEY}`,
        secretAccessKey: `${process.env.REACT_APP_S3_SECRET_KEY}`,
    });

    const params = {
        Bucket: "partnerdocuments",
        Key: `${filename.toUpperCase()}_${filetype}.${fileExt}`,
        Body: fileContent,
        ContentType: fileContent.type
    };

    return s3.upload(params).promise()
};

export default uploadFile
