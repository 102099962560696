import { Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { CSVLink } from "react-csv";

function FulfillAdmin() {
    const [data, setData] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    useEffect(() => {
        const getFulfillData = async () => {
            setLoading(true)
            const resp = await CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/partner-brand?page=${page}`);
            setTotalPages(resp.data.totalResults)
            setData(resp.data.results)
            setFilteredData(resp.data.results);
            setLoading(false)
        }
        getFulfillData();
    }, [page])


    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (_, record) => {
                return (
                    <>
                        {moment(record.createdAt).format("DD/MM/YY")}
                        <br />
                        {moment(record.createdAt).format("h:mm A")}
                    </>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone No.',
            dataIndex: ["phone", "number"],
        },
        {
            title: 'Company',
            dataIndex: 'company',
        },
        {
            title: 'Weight Per Order',
            dataIndex: 'weightPerOrder',
        },
        {
            title: 'Orders Per Day',
            dataIndex: 'ordersPerDay',
        },
        {
            title: 'Website',
            dataIndex: 'website',
            render: (text, record) =>
                <a href={`http://${text}`} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{text}</a>
        },
    ]

    const headers = [
        {
            label: 'CreatedAt',
            key: 'createdAt',
        },
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'Phone No.',
            key: "phone.number",
        },
        {
            label: 'Company',
            key: 'company',
        },
        {
            label: 'Weight Per Order',
            key: 'weightPerOrder',
        },
        {
            label: 'Orders Per Day',
            key: 'ordersPerDay',
        },
        {
            label: 'Website',
            key: 'website',
        },
    ]

    return (
        <>
            <h2>Fulfill and Deliver</h2>
            <div className="admin-options">
                <div className="dash-table-search">
                    <input type="text"
                        placeholder="Search..."
                        onChange={e => {
                            const currValue = e.target.value.toLowerCase();
                            const filteredData_ = data.filter(entry =>
                                entry.name.toLowerCase().includes(currValue) ||
                                entry.phone.number.toLowerCase().includes(currValue) ||
                                entry.company.toLowerCase().includes(currValue) ||
                                entry.website.toLowerCase().includes(currValue)
                            );
                            setFilteredData(filteredData_);
                        }}
                    />
                </div>
                <CSVLink filename={"Warehouse.csv"} data={data} headers={headers}>
                    <button id="btn3" style={{ padding: "4px 8px" }}>EXPORT</button>
                </CSVLink>
            </div>

            <div className="dash-table">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{ position: ["topLeft"], pageSize: 10, total: totalPages }}
                    onChange={(e) => setPage(e.current)}
                    loading={loading}
                    rowKey="id" />
            </div>
        </>
    )
}

export default FulfillAdmin;