import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

function Success() {
    return (
        <div className="">
            <Result
                status="success"
                title="Success!! Our Team will Contact you soon!!"
                extra={[
                    <Link to="/" key={1}>
                        <Button type="primary" key="console">
                            Go Back
                        </Button>
                    </Link>
                ]}
            />
        </div>
    )
}

export default Success;