import axios from "axios"

const CustomAxios = axios.create({});

CustomAxios.interceptors.request.use(req => {
    CustomAxios.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("accessToken")}`;
    return req;
})

CustomAxios.interceptors.response.use(resp => resp, async err => {
    if (err.response.status === 401) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/refresh-tokens`,
            { "refreshToken": localStorage.getItem("refreshToken") })
            .then(resp => {
                CustomAxios.defaults.headers.common["Authorization"] = `Bearer ${resp.data.access.token}`;
                sessionStorage.setItem("accessToken", resp.data.access.token);
                localStorage.setItem("refreshToken", resp.data.refresh.token);
                return CustomAxios(err.config)
            })
            .catch(err => {
                return CustomAxios(err.config)
            })
    }
    return Promise.reject(err);
})

export default CustomAxios;