import "./styles/DeliveryFleet.css"
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import HowSlide from "../../components/HowSlide";
import DeliveryFleetFormComp from "../../components/DeliveryFleetFormComp";
import ImageComp from "../../components/ImageComp"

function DeliveryFleet() {
    const fullFillArr = [
        {
            title: "Hire riders in 33 hours",
            desc: "Our wide network gets activates as we receive your requirement. We have been instrumental in activating last mile delivery of most leading brands in multiple cities. ",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_253.png`
        },
        {
            title: "Get Managed Services",
            desc: "We reduce your effort to distribute, make the process secure, have industry experts to handle your business, ensure delivery partners deliver as per your requirement.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_254.png`
        },
        {
            title: "Secure your products and COD",
            desc: "We handle your products and cash with Care. Our charges on COD remittance is Zero and same day Cash deposit makes us The most admired brand.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/dfle1.png`
        },
        {
            title: "Launch New Cities",
            desc: "We are always open to launching new cities and PIN codes basis your business need. Expanding your addressable market is our biggest goal!",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/dfle2.png`
        },
        {
            title: "Happy Customer",
            desc: "Delivering an amazing experience to our customers is our value. We keep the customer demand, wait time, special request before everything else.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/dfle3.png`
        }
    ]

    return (
        <>
            <Topbar />
            <div className="">
                <div className="fullfillTop" id="deliv-fleet-top">
                    <div className="fullfillright">
                        <DeliveryFleetFormComp />
                    </div>
                    <div className="fullfillLeft">
                        <div className="fullfillTitle" style={{ fontSize: "2.2rem" }}>Hire Fleet in <span style={{ color: "#00B050" }}>33 hrs</span></div>
                        <div className="fullfillDesc">
                            <div className="fullfillDescTop">
                                We enable Quick-Commerce for D2C, E-Commerce and Courier brands. We deliver Grocery, FMCG, Pharma, E-Commerce and anything sold Online.
                            </div>
                            <div className="fullfillDescBottom">
                                <span style={{ fontWeight: "bolder" }}>We Curate Delivery Fleet, as per your need</span>
                                <ul>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Send</span> your requirement</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Hire</span> trained and verified fleet</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Enhance</span> user Experience</li>
                                </ul>
                            </div>
                        </div>
                        <div className="fullfillLeftImg">
                            <ImageComp img={"homeMain.png"} maxWidth={"350px"} width={"100%"} />
                        </div>
                    </div>
                </div>

                <div className="homeBrands">
                    <div className="brandTitle">Brands that trust <span style={{ color: "#00B050" }}>Send33</span></div>
                    <div className="brandLogos">
                        <ImageComp img={"image_28.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_30.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"BoiveadLogo.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"fandp.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_29.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"mm_1.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                    </div>
                </div>

                <div className="delMain">
                    <div className="delMainLeft">
                        <div className="fullfillTitle" style={{ fontSize: "2.2rem" }}>Delivering <span style={{ color: "#00B050" }}>Express</span></div>
                        <div className="fullfillDesc">
                            <div className="fullfillDescTop">
                                {"Speed and convenience is becoming more important than ever before. We make brands score competitive edge by getting into hands of users < 33 mins"}
                            </div>
                            <div className="fullfillDescBottom">
                                <span style={{ fontWeight: "bolder" }}>What we do</span>
                                <ul>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>33 mins</span> delivery</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Slotted</span> delivery</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Same Day</span> distribution</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>National</span> partner to brands</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="delMainRight">
                        <ImageComp img={"map.png"} />
                    </div>
                </div>

                <div className="delMain">
                    <div className="delMainRight" id="del-img">
                        <ImageComp img={"delive.png"} width={"300px"} />
                    </div>
                    <div className="delMainLeft">
                        <div className="fullfillTitle" style={{ fontSize: "2.2rem" }}>Amazing <span style={{ color: "#00B050" }}>Experience</span></div>
                        <div className="fullfillDesc">
                            <div className="fullfillDescTop">
                                {"Only impression about an online brand is about how Delivery Partner makes the customer feel. "}
                            </div>
                            <div className="fullfillDescBottom">
                                <span style={{ fontWeight: "bolder" }}>We create impact</span>
                                <ul>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Value</span> your customers</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Empathize</span> to specific requests</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>Enable</span> long term relationship</li>
                                    <li><span style={{ color: "#00B050", fontWeight: "bold" }}>5-star</span> ratings and reviews</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fullfillPrioContainer">
                    <div className="fullfillPrio">
                        <div className="fullfillPriHeader">Benefits of Partnering with Send33</div>
                        <div className="fullfillPrioItems">
                            <div className="fullfillPriItem">
                                <ImageComp img={"full1.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    Express Delivery
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <ImageComp img={"full2.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    CSAT guaranteed
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <ImageComp img={"full3.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    {"COD protected"}
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <ImageComp img={"full4.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    {"Verified fleet"}
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <ImageComp img={"full5.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    Trained fleet
                                </div>
                            </div>
                            <div className="fullfillPriItem">
                                <ImageComp img={"full6.png"} width={"300px"} maxWidth={"50px"} />
                                <div className="fullfillPriItemDesc">
                                    Route optimization
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fullfillHow">
                    <div className="howHeader">How it <span style={{ color: "#00B050" }}>works?</span></div>
                    <HowSlide howArr={fullFillArr} />
                </div>

            </div>
            <Footer />
        </>
    )
}

export default DeliveryFleet;