import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";

import "./AdminLayout.scss";

function PartnerLayout() {

    return (
        <div className="admin-dash-main">
            <div className="admin-side">
                <AdminSidebar />
            </div>
            <div className="admin-main-container">
                <div className="admin-main">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default PartnerLayout;
