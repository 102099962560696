import "./styles/PartnerVerifDocs.css"
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import CustomAxios from "../../CustomAxios/CustomAxios";
import uploadFile from "../../utils/AWS_Upload"
import states from "../../utils/States";
import { Dropdown } from 'antd';

function RiderVerifDocs() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [permAddrs, setPermAddrs] = useState("");
    const [licenceCheck, setLicenceCheck] = useState(false);

    const [upldLoad, setUpldLoad] = useState(false);

    const [section, setSection] = useState(1);

    const navigate = useNavigate()

    const [upldFiles, setUpldFiles] = useState(
        {
            Aadhaar_Front: [],
            Aadhaar_Back: [],
            PAN: [],
            Licence: []
        }
    )

    const formik = useFormik({
        initialValues: {
            currentAddress: "",
            permanentAddress: "",
            phone: "",
            city: "",
            state: "",
            aadhaar: "",
            pan: "",
            licence: "",
            vehicle: "",
        },

        validationSchema: Yup.object({
            currentAddress: Yup.string(),
            permanentAddress: Yup.string(),
            phone: Yup.string().max(10).min(10),
            city: Yup.string(),
            state: Yup.string(),
            aadhaar: Yup.string().max(12).min(12),
            pan: Yup.string().max(10).min(10),
            licence: Yup.string(),
            vehicle: Yup.string(),
        }),

        onSubmit: async (values) => {
            if (
                upldFiles.Aadhaar_Front.length === 0 ||
                upldFiles.Aadhaar_Back.length === 0 ||
                upldFiles.PAN.length === 0
            ) {
                setError(true);
                setErrorMessage("Upload the required documents")
                setLoading(false);
                return
            }

            setLoading(true);
            const data = {
                "adhaar": values.aadhaar.toUpperCase(),
                "adhaarUrlFront": upldFiles.Aadhaar_Front[1],
                "adhaarUrlBack": upldFiles.Aadhaar_Back[1],
                "pan": values.pan.toUpperCase(),
                "panUrl": upldFiles.PAN[1],
                "vehicleType": values.vehicle,
                "drivingLicense": values.licence === "" ? "Don't have licence" : values.licence.toUpperCase(),
                "drivingLicenseUrl": upldFiles.Licence[1],
            }

            CustomAxios.patch(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`, data)
                .then(resp => {
                    localStorage.setItem("userDoc", JSON.stringify(resp.data));
                    navigate("/partner-success");
                    setLoading(false);
                })
                .catch(err => {
                    setErrorMessage(err.response.data.message);
                    setError(!error);
                    setLoading(false);
                })
        }
    });

    useEffect(() => {
        setLoading(true)
        CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`)
            .then(resp => {
                formik.setErrors(false)
                resp.data.phone && formik.setFieldValue("phone", resp.data.phone)
                resp.data.currentAddress && formik.setFieldValue("currentAddress", resp.data.currentAddress)
                resp.data.permanentAddress && formik.setFieldValue("permanentAddress", resp.data.permanentAddress)
                resp.data.state && formik.setFieldValue("state", resp.data.state)
                resp.data.city && formik.setFieldValue("city", resp.data.city)
                resp.data.adhaar && formik.setFieldValue("aadhaar", resp.data.adhaar)
                resp.data.pan && formik.setFieldValue("pan", resp.data.pan)
                resp.data.vehicleType && formik.setFieldValue("vehicle", resp.data.vehicleType)
                resp.data.licence && formik.setFieldValue("licence", resp.data.licence !== "Don't have licence" ? "" : resp.data.licence)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const awsUpload = (e, fileType) => {
        let file = e.target.files[0];
        let fileExt = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
        if (file.size / 1000000 > 6) {
            setErrorMessage("File size limit 5 MB")
            return
        }
        if (file) {
            setUpldLoad(true)
            uploadFile(file, fileType, formik.values[e.target.name], fileExt)
                .then(data => {
                    setUpldFiles({ ...upldFiles, [fileType]: [data.Key, data.Location] })
                    setUpldLoad(false)
                })
                .catch()
        }
    }

    const showNext = () => {
        if (section === 1) {
            setSection(section + 1);
        }

        if (section === 2) {
            setLoading(true);

            if (formik.values.phone.length !== 10) {
                return
            }
            CustomAxios.patch(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`, {
                phone: formik.values.phone
            }).then((resp) => {
                setSection(section + 1);
                setLoading(false);

            }).catch(err => {
                console.log(err)
                setLoading(false);
            })
        }

        if (section === 3) {
            setLoading(true);
            if (
                formik.values.currentAddress === "" ||
                formik.values.state === "" ||
                formik.values.city === "") {
                return
            }
            CustomAxios.patch(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`, {
                currentAddress: formik.values.currentAddress,
                permanentAddress: permAddrs ? formik.values.currentAddress : formik.values.permanentAddress,
                city: formik.values.city,
                state: formik.values.state

            }).then((resp) => {
                setSection(section + 1);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    const showPrev = () => {
        setSection(section - 1);
    }

    const states_ = {
        "Haryana": ["Gurgaon", "Faridabad"],
        "Delhi": ["Delhi",],
        "Uttar Pradesh": ["Noida", "Ghaziabad", "Kanpur", "Lucknow", "Varanasi"],
        "West Bengal": ["Kolkata",],
        "Punjab": ["Mohali", "Chandigarh"],
        "Rajasthan": ["Jaipur"]
    }

    const items = [
        {
            key: '1',
            label: (
                <span onClick={() => {
                    sessionStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("user");
                    localStorage.removeItem("userDoc");
                    window.location.replace("/partner-login")
                }}>
                    Logout
                </span>
            ),
        }
    ]

    return (
        <div className="partner-verif-container">
            <div className="partner-verif-top">
                <Dropdown
                    menu={{ items }}
                    placement="bottom"
                    arrow={{ pointAtCenter: true }}
                >
                    <img src="/img/avatar-default.jpg" alt="" style={{ cursor: "pointer" }} />
                </Dropdown>
            </div>
            <form className="rider-verify" onSubmit={formik.handleSubmit}>
                {
                    section === 1 &&
                    <div className="rider-section1">
                        <div className="rider-verify-title">
                            <h1>Rider <span style={{ color: "#00B050" }}>Verification</span></h1>
                        </div>
                        <div className="rider-verify-aadhar">
                            <div className="rider-aadhar-front">
                                <span style={{ margin: "1rem 0" }}>Delivery Partner Agreement</span>
                                <div className="" style={{ textAlign: "justify" }}>
                                    Send33 Express Private Limited shall collect Delivery Partner’s information at any time, to
                                    establish the Delivery partner’s identity and for other legal compliances (internal and external).
                                    Send33 reserves the right to store, process, access and use that information for business purposes
                                    and needs, background check, verification, marketing, service, development, analytics, research,
                                    and any other purpose as Send33 may deem fit and in accordance with applicable laws. You
                                    hereby expressly consent to such collection and use of your information including Aadhar
                                    details, Bank Account details, Government approved identity numbers etc.
                                </div>
                            </div>
                        </div>
                        <button id="btn4" onClick={() => showNext()} type="button">Next</button>
                    </div>
                }

                {
                    section === 2 &&
                    <div className="rider-section1">
                        <div className="rider-verify-title">
                            <h1>Rider <span style={{ color: "#00B050" }}>Verification</span></h1>
                        </div>
                        <div className="rider-verify-aadhar">
                            <div className="rider-aadhar-front">
                                <span>Phone Number*</span>
                                <input
                                    type="text"
                                    name="phone"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                                <span style={{ color: "red" }}>{formik.errors.phone}</span>
                            </div>
                        </div>
                        <button id="btn4" onClick={() => showNext()} type="button">Next</button>
                        {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                    </div>
                }

                {
                    section === 3 &&
                    <div className="rider-section1">
                        <div className="rider-verify-title">
                            <h1>Rider <span style={{ color: "#00B050" }}>Verification</span></h1>
                        </div>

                        <div className="rider-aadhar-front">
                            <span>Current Address*</span>
                            <input
                                type="text"
                                name="currentAddress"
                                onChange={formik.handleChange}
                                value={formik.values.currentAddress}
                            />
                            <span style={{ color: "red" }}>{formik.errors.currentAddress}</span>
                        </div>

                        <div className="rider-verify-aadhar">
                            <div className="rider-aadhar-front">
                                <span>Permanent Address*</span>
                                <div className="" id="rider-perm-addre">
                                    <input type="checkbox" id="rider-adrs-chk" onChange={e => setPermAddrs(e.target.checked)} />
                                    <label htmlFor="rider-adrs-chk">same as current Address</label>
                                </div>
                                {
                                    !permAddrs &&
                                    <input
                                        type="text"
                                        name="permanentAddress"
                                        onChange={formik.handleChange}
                                        value={formik.values.permanentAddress}
                                    />
                                }
                                <span style={{ color: "red" }}>{formik.errors.permanentAddress}</span>
                            </div>
                        </div>

                        <div className="rider-verify-aadhar">
                            <div className="rider-aadhar-front">
                                <span>State*</span>
                                <select
                                    name="state"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    style={{ outline: "none", width: "60%", marginTop: "8px", fontSize: "1rem" }}
                                >
                                    <option value="" hidden></option>
                                    {
                                        states.map((state, i) => {
                                            return (
                                                <option value={state} key={i}>{state}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span style={{ color: "red" }}>{formik.errors.state}</span>
                            </div>
                        </div>

                        <div className="rider-verify-aadhar">
                            <div className="rider-aadhar-front">
                                <span>City*</span>
                                <select
                                    name="city"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    style={{ outline: "none", width: "60%", marginTop: "8px", fontSize: "1rem" }}
                                >
                                    <option value="" hidden></option>
                                    {
                                        formik.values.state !== "" &&
                                        states_[formik.values.state] &&
                                        states_[formik.values.state].map((city, i) => {
                                            return (
                                                <option value={city} key={i}>{city}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span style={{ color: "red" }}>{formik.errors.city}</span>
                            </div>
                        </div>
                        <button id="btn5" onClick={() => showPrev()} type="button">Previous</button>
                        <button id="btn4" style={{ margin: "0 1rem" }} onClick={() => showNext()} type="button">Next</button>
                        {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                    </div>
                }

                {section === 4 &&
                    <>
                        <div className="rider-verify-aadhar">
                            <span>Aadhaar*</span>
                            {upldLoad && <span style={{ margin: "1rem 1rem" }}><Spin /></span>}
                            <div className="rider-aadhar-front">
                                <span style={{ fontWeight: "bold" }}>Aadhaar Number</span>
                                <input
                                    type="aadhaar"
                                    name="aadhaar"
                                    onChange={formik.handleChange}
                                    value={formik.values.aadhaar}
                                />
                                <span style={{ color: "red" }}>{formik.errors.aadhaar}</span>
                            </div>
                            {formik.values.aadhaar.length === 12 &&
                                <>
                                    <div className="rider-aadhar-back">
                                        <span style={{ fontWeight: "bold" }}>Upload Front of Aadhaar</span>
                                        <div className="rider-verif-upload">
                                            <div className="upld-btn">
                                                <input
                                                    onChange={(e) => awsUpload(e, "Aadhaar_Front")}
                                                    name="aadhaar"
                                                    type="file"
                                                    id="actual-btn"
                                                    hidden
                                                />
                                                <label htmlFor="actual-btn">Upload</label>
                                                <span id="file-chosen">
                                                    {
                                                        !upldFiles.Aadhaar_Front[0] ? "No file chosen" : `${upldFiles.Aadhaar_Front[0]}`
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rider-aadhar-back">
                                        <span style={{ fontWeight: "bold" }}>Upload Backside of Aadhaar</span>
                                        <div className="rider-verif-upload">
                                            <div className="upld-btn">
                                                <input
                                                    onChange={(e) => awsUpload(e, "Aadhaar_Back")}
                                                    name="aadhaar"
                                                    type="file"
                                                    id="actual-btn5"
                                                    hidden
                                                />
                                                <label htmlFor="actual-btn5">Upload</label>
                                                <span id="file-chosen">
                                                    {
                                                        !upldFiles.Aadhaar_Back[0] ? "No file chosen" : `${upldFiles.Aadhaar_Back[0]}`
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="rider-verify-pan">
                            <span>PAN*</span>
                            {upldLoad && <span style={{ margin: "1rem 1rem" }}><Spin /></span>}
                            <div className="rider-aadhar-front">
                                <span style={{ fontWeight: "bold" }}>PAN Number</span>
                                <input
                                    type="text"
                                    name="pan"
                                    onChange={formik.handleChange}
                                    value={formik.values.pan}
                                />
                                <span style={{ color: "red" }}>{formik.errors.pan}</span>
                            </div>

                            {
                                formik.values.pan.length === 10 &&
                                <div className="rider-pan-front">
                                    <span style={{ fontWeight: "bold" }}>Upload PAN</span>
                                    <div className="rider-verif-upload">
                                        <div className="upld-btn">
                                            <input
                                                onChange={(e) => awsUpload(e, "PAN")}
                                                name="pan"
                                                type="file"
                                                id="actual-btn3"
                                                hidden
                                            />
                                            <label htmlFor="actual-btn3">Upload</label>
                                            <span id="file-chosen">
                                                {
                                                    !upldFiles.PAN[0] ? "No file chosen" : `${upldFiles.PAN[0]}`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="rider-verify-licence">
                            <span>Vehicle Type*</span>
                            <div className="rider-aadhar-front">
                                <div className="rFormGenderInput" onChange={formik.handleChange}>
                                    <div className="">
                                        <input type="radio" name="vehicle" value="Bike - Petrol" defaultChecked={formik.values.vehicle === "Bike - Petrol" ? true : false} />
                                        <label>Bike - Petrol</label>
                                    </div>
                                    <div className="">
                                        <input type="radio" name="vehicle" value="eeco/tata ace" defaultChecked={formik.values.vehicle === "eeco/tata ace" ? true : false} />
                                        <label>Eeco / Tata ace</label>
                                    </div>
                                    <div className="">
                                        <input type="radio" name="vehicle" value="Cycle" defaultChecked={formik.values.vehicle === "Cycle" ? true : false} />
                                        <label>Cycle</label>
                                    </div>
                                </div>
                                <span style={{ color: "red" }}>{formik.errors.vehicle}</span>
                            </div>
                        </div>

                        <div className="rider-verify-licence">
                            <span>Licence</span>
                            <div className="">
                                <input type="checkbox" id="rider-licenc-chk" onChange={e => setLicenceCheck(e.target.checked)} checked={licenceCheck} />
                                <label htmlFor="rider-licenc-chk">Don't have licence</label>
                            </div>
                            {
                                !licenceCheck &&
                                <>
                                    <div className="rider-aadhar-front">
                                        <span style={{ fontWeight: "bold" }}>Licence Number</span>
                                        <input
                                            type="text"
                                            name="licence"
                                            onChange={formik.handleChange}
                                            value={formik.values.licence}
                                        />
                                        <span style={{ color: "red" }}>{formik.errors.licence}</span>
                                    </div>
                                    {
                                        formik.values.licence.length >= 8 && formik.values.licence.length <= 25 ?
                                            <div className="rider-licence-front">
                                                <span style={{ fontWeight: "bold" }}>Upload Licence</span>
                                                {upldLoad && <span style={{ margin: "1rem 1rem" }}><Spin /></span>}
                                                <div className="rider-verif-upload">
                                                    <div className="upld-btn">
                                                        <input
                                                            onChange={(e) => awsUpload(e, "Licence")}
                                                            name="licence"
                                                            type="file"
                                                            id="actual-btn4"
                                                            hidden
                                                        />
                                                        <label htmlFor="actual-btn4">Upload</label>
                                                        <span id="file-chosen">
                                                            {
                                                                !upldFiles.Licence[0] ? "No file chosen" : `${upldFiles.Licence[0]}`
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : ""
                                    }
                                </>
                            }
                        </div>
                        <button id="btn5" onClick={() => showPrev()} type="button">Previous</button>
                        <button id="btn4" style={{ fontWeight: "600", margin: "0 1rem" }} type="submit">Submit</button>
                        <br />
                        <br />
                        {errorMessage && <span style={{ margin: "0 1rem", color: "red" }}>{errorMessage}</span>}
                        {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                    </>
                }

            </form>
        </div>
    )
}

export default RiderVerifDocs;