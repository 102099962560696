import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Home

import Home from './pages/home/Home';
import About from './pages/home/About';
import Services from './pages/home/Services';
import Rider from "./pages/home/Rider"
import Warehouse from './pages/home/Warehouse';
import Fulfill from './pages/home/Fulfill';
import DeliveryFleet from './pages/home/DeliveryFleet';
import Privacy from './pages/home/Privacy';
import Page404 from './pages/home/Page404';
import Success from './pages/home/Success';

// Admin Dashboard

import AdminLogin from '././pages/admin/auth/Login';
import AdminLoggedIn from "./pages/admin/auth/AdminLoggedIn";

import AdminLayout from "./pages/admin/components/AdminLayout"
import AdminMain from './pages/admin/AdminMain';
import FulfillAdmin from "./pages/admin/FulfillAdmin"
import OwnDelivAdmin from "./pages/admin/OwnDelivAdmin"
import RiderAdmin from "./pages/admin/RiderAdmin"
import WarehouseAdmin from "./pages/admin/WarehouseAdmin"

import PartnerUpldDocs from "./pages/admin/Partner";

import CreateJob from "./pages/jobs/CreateJob"

// Partner Dashboard

import PartnerLogin from './pages/partner/auth/Login';
import PartnerLoggedIn from "./pages/partner/auth/PartnerLoggedIn"
import PartnerRegister from './pages/partner/auth/Register';

import PartnerLayout from './pages/partner/components/layout/PartnerLayout';
import PartnerMain from './pages/partner/PartnerMain';
import PartnerVerify from "./pages/partner/PartnerVerifDocs"
import PartnerDocs from './pages/partner/PartnerDocs';
import PartnerProfile from './pages/partner/PartnerProfile';
import PartnerSuccess from './pages/partner/PartnerSuccess';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/rider" element={<Rider />} />
        <Route path="/warehouse" element={<Warehouse />} />
        <Route path="/fulfill" element={<Fulfill />} />
        <Route path="/delivery-fleet" element={<DeliveryFleet />} />
        <Route path="/success" element={<Success />} />

        {/* Admin Dashboard*/}

        <Route path="/login" element={<AdminLogin />} />

        <Route element={<AdminLoggedIn />}>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="" element={<AdminMain />} />
            <Route path="fulfill" element={<FulfillAdmin />} />
            <Route path="delivery-fleet" element={<OwnDelivAdmin />} />
            <Route path="delivery-partner" element={<RiderAdmin />} />
            <Route path="warehouse" element={<WarehouseAdmin />} />
            <Route path="partner-upload-docs" element={<PartnerUpldDocs />} />
            <Route path="create-job" element={<CreateJob />} />
          </Route>
          <Route path="/success" element={<Success />} />
        </Route>

        {/* Partner Dashboard*/}

        <Route path="/partner-register" element={<PartnerRegister />} />
        <Route path="/partner-login" element={<PartnerLogin />} />
        <Route path="/partner-verify" element={<PartnerVerify />} />
        <Route element={<PartnerLoggedIn />}>
          <Route path='/partner' element={<PartnerLayout />}>
            <Route path="" element={<PartnerMain />} />
            <Route path="partner-docs" element={<PartnerDocs />} />
            <Route path="partner-profile" element={<PartnerProfile />} />
          </Route>
          <Route path="partner-success" element={<PartnerSuccess />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
