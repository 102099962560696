import { useState } from "react";
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer"
import "./styles/About.css";

const teamArr = [
    {
        name: "Dimpy Dewan",
        postn: "Founder & CEO",
        desc: `I believe the need for Quick-Commerce is Real! I and those who are Gen X, Y, Z are 
        backed by unplanned and impulsive purchases, so Express-Delivery is going to be a win-win 
        for both users and for brands. I am determined to solve this problem for brands by making it 
        highly convenient and low cost; and I will solve it for users by getting them the products in 
        33 mins ~ 33 hrs, depending upon the product category. As an Enabler, I want to offload large 
        and small brands of the heavy lifting each is doing in terms of technology, infrastructure, processes, 
        team and analytics so that brands can just focus on their core that is product innovation, 
        sales and marketing.`,
        img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Dimpy.jpeg`
    }/* ,
    {
        name: "Ashish Kumar",
        postn: "Director Operations",
        desc: `I have been instrumental in activating last mile delivery of almost every leading
        brand in this country and that too in multiple cities. We are building and extending optimum
        ways of moving freight, reducing effort to distribute, making the process secure, seamless
        and most viable to outsource and track with a click of a button. We are already present in
        Gurgaon, Faridabad, Delhi, Kolkata, Jaipur with our own dedicated fleet and network and shall
        continue to grow and expand in Length and Breadth of the country.`,
        img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Ashish.jpeg`
    },
    {
        name: "Saurav Fouzdar",
        postn: "Chief Technology Officer",
        desc: `Saurav holds a Bachelor’s degree in Electronics & Communication Engineering from IIIT, Surat.
        Prior to Send33, Saurav was part of early engineering team at Breathe ESG and  Spoofsense.ai, where
        he built ESG reporting and enterprise platforms. He strongly believes in the potential of Indian
        ecommerce market, that is expected to reach US$ 350 billion by 2030. Everything quick is clearly
        the immediate future! To optimize operational cost while keeping up with user demand, shall
        foster shared fulfillment and last mile culture in e-commerce . Send33 will make user experience
        blazing fast and seamless by delivering product in just few minutes which today takes multiple
        days and change of hands.`,
        img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/savrau.png`
    },
    {
        name: "Debarpita Pande",
        postn: "Independent Legal Counsel",
        desc: `I am a Law Graduate from National Law University, Nagpur and Legal Associate
        with Shardul Amarchand Mangaldas and Co. When I met Dimpy and heard about what she
        is building, we both just knew that we have to work together. With double-digit app
        penetration, Express Fulfilment and Logistics is a big market opportunity and I have
        full confidence we are in the right space and at the right time. Democratising
        Quick-Commerce and making it convenient and cost effective for small and big
        brands, bringing women to the forefront of logistics and driving actions towards
        clean-tech makes it purposeful at large scale. My focus is to make the foundation
        of the company and everyone associate to it well-governed, compliant, and inclusive.
        Send33 mission is to provide employment to 4Lac+ in India and that involves ensuring 
        the organisation is process-driven and culture-driven from Day1 to be on the path to
        becoming the Most Admired Brand to be associated with.`,
        img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Debarpita.jpeg`
    },
    {
        name: "Manas Kapoor",
        postn: "Fund Raising and Investor Relations",
        desc: `Ex-FTV, Oberoi, Taj, Hyatt. Expertise in mapping Founder goals with Investor interest and deal structuring`,
        img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/manas.png`
    },
    {
        name: "Abhishek",
        postn: "Head of Sustainability",
        desc: `My last 3 years have gone in building Supply of EV’s, battery swaps and associated
        services for leading brands. It thrills me to build Send33 into a brand that is backed with
        Purpose. I have known Dimpy for a long time and our vision as a team is to build a scalable
        and sustainable brand. I shall be leveraging my skills and network to work on Clean-Tech,
        bringing Women to the forefront of Logistics with adequate safety, and creating financial
        inclusiveness through better opportunities. There shall be a lot of great and hard work
        that shall go behind in breaking age-old shackles but I am sure we shall look back to this
        with a lot of Pride in what we have built.
        `,
        img: ""
    }, */
]

const aboutCol = window.innerWidth > 600 ? 4 : 3;

function About() {
    const [toggleItems, setToggleItems] = useState([]);

    const toggle = i => {
        const showItems = toggleItems.slice();

        if (showItems.includes(i)) {
            showItems.splice(showItems.indexOf(i), 1);
            setToggleItems(showItems);
        } else {
            showItems.push(i);
            setToggleItems(showItems);
        }
    }

    return (
        <>
            <Topbar />
            <div className="about">

                <div className="aboutBld">
                    <div className="abtBldLeft">
                        <div className="abtBldTitle">Building a great network of dark stores in India</div>
                        <div className="btBldDesc">
                            Send33 is Democratising Express Fulfilment and Delivery in 33 mins* for Consumer Brands, D2C Brands, Ecommerce Brands and Courier Brands. We work with small to large brands so as to make them the most admired brand in the online space. Send33 is Convenient and Low Cost way of enabling Quick-Commerce for anything that is sold Online through our Tech-Enabled Distribution, Fulfilment and Delivery network.
                        </div>
                    </div>
                    <div className="abtBldRight">
                        <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Checking_boxes-cuate_1.png`} alt="" />
                    </div>
                </div>

                <div className="aboutBld" id="abtBld">
                    <div className="abtBldRight">
                        <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_150.png`} alt="" />
                    </div>
                    <div className="abtBldLeft">
                        <span className="coreTeamHeader">OUR MISSION</span>
                        <div className="coreTeamTitle">Building a great network of dark stores in India</div>
                        <div className="btBldDesc">
                            Delivering NOW is not a Fashion but our Passion to make brand unit economic positive. Your high burn on account of cart abandonment, cancellations and customer Churn shall significantly drop as you enable our services.
                        </div>
                    </div>
                </div>

                <div className="abtStatContainer">
                    <div className="abtStat">
                        <div className="abtStatTop">
                            The user behaviour has evolved into buying patterns that are impulse and unplanned making “Time to Deliver” as the KEY parameter in E-Commerce.
                        </div>
                        <div className="abtStatCenter">
                            <span className="abtStatCenterTitle">Do you know?</span>
                            <div className="abtStatMain">
                                <div className="abtStatItem">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_246.png`} alt="" />
                                    <span className="abtStatItemTitle">Users buying decision is based on Delivery Speed</span>
                                    <span className="abtStatItemNum">97%</span>
                                </div>
                                <div className="abtStatItem">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_245.png`} alt="" />
                                    <span className="abtStatItemTitle">Users are willing to pay for Same Day Delivery</span>
                                    <span className="abtStatItemNum">41%</span>
                                </div>
                                <div className="abtStatItem">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_243.png`} alt="" />
                                    <span className="abtStatItemTitle">Users are willing to spend Extra to get Delivery in a few minutes</span>
                                    <span className="abtStatItemNum">24%</span>
                                </div>
                            </div>
                        </div>
                        <div className="abtStatBottom">
                            Send33 gets your products into the hands of Users at 33 Mins speed! Our high Delivery % enables brands to reduce the total cost of operations, thus making the business model sustainable and scalable
                        </div>
                        <a href="/fulfill" style={{ alignSelf: "flex-start" }}>
                            <button id="btn2" style={{ width: "200px" }}>Become a Send33 Partner</button>
                        </a>
                    </div>
                </div>

                <div className="aboutBld" id="abtStry">
                    <div className="abtBldLeft">
                        <span className="coreTeamHeader">OUR STORY</span>
                        <div className="abtBldDesc">
                            Send33 is a Gurgaon based startup, founded by Dimpy Dewan. She is a 2X entrepreneur and has been the launchpad and Head of User Experience for leading Fintech and Mobility brands. The pain of customers has been the same for over a decade now.. “Where is my product?” It takes the customer into a loop of wasting time and energy in re-discovering on other online and offline channels and leaving the brand for good!
                        </div>
                    </div>
                    <div className="abtBldRight">
                        <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Frame.png`} alt="" />
                    </div>
                </div>

                <div className="coreTeamContainer">
                    <div className="coreTeam">
                        <span className="coreTeamHeader">MEET THE CORE TEAM</span>
                        <div className="coreTeamMain">
                            <div className="coreTeamItems"
                                style={{ display: "block" }}>
                                {
                                    teamArr.map((team, i) => {
                                        return (
                                            <div className="coreTeamItem" style={toggleItems.includes(i) ? { gridColumn: `1/${aboutCol}` } : {}} key={i}>
                                                <div className="coreTeamBox">
                                                    <img src={team.img} alt="" />
                                                    <i className="fa-solid fa-chevron-right" id="rightArrow" onClick={() => toggle(i)}></i>
                                                </div>
                                                <div className="coreTeamName">{team.name}</div>
                                                <div className="coreTeamDetail">{team.postn}</div>
                                                {toggleItems.includes(i) && <div className="coreTeamDesc">{team.desc}</div>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About;