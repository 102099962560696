import { useState } from "react";
import { Outlet } from "react-router-dom";
import Siderbar from "../sidebar/Sidebar";
import "./PartnerLayout.scss";
import Topbar from "../topbar/Topbar"

function PartnerLayout() {
    const [openSide, setOpenSide] = useState(true);

    const setSideOpen = (val) => setOpenSide(val);

    return (
        <div className="partner-dash-main">
            <div className="partner-side">
                {openSide && <Siderbar setSideOpen={setSideOpen} openSide={openSide} />}
            </div>
            <div className="partner-main-container">
                <Topbar setSideOpen={setSideOpen} openSide={openSide} />
                <div className="partner-main">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default PartnerLayout;
