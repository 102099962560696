import "./styles/RiderFormComp.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Spin } from 'antd';

function RiderFormComp() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            email: "",
            aadhaar: "",
            city: "",
            preferredWorkLocations: "",
            gender: "",
            DOB: "",
            workType: [],
            vehicle: "",
            licenceType: "",
            licenceNumber: "",
            address: ""
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            phone: Yup.string().max(10).min(10).required("Required"),
            email: Yup.string().email("Invalid Email").required("Required"),
            aadhaar: Yup.string().max(12).min(12).required("Required"),
            city: Yup.string().required("Required"),
            preferredWorkLocations: Yup.string().required("Required"),
            gender: Yup.string().required("Required"),
            DOB: Yup.string().required("Required"),
            workType: Yup.array().min(1).required("Required"),
            vehicle: Yup.string().required("Required"),
            licenceType: Yup.string().required("Required"),
            licenceNumber: Yup.string().required("Required"),
            address: Yup.string().required("Required")
        }),

        onSubmit: async (values) => {
            setLoading(true);
            const details = {
                ...values,
                phone: { "countryCode": "+91", "number": values.phone },
                preferredWorkLocations: [values.preferredWorkLocations],
            }

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/partner-rider`, details)
                .then(resp => {
                    console.log(resp.status);
                    setLoading(false);
                    navigate("/success");
                }).catch(err => {
                    setErrorMessage(err.response.data.message);
                    setLoading(false);
                    setError(!error);
                })

        }

    });

    const workType = ["Ecommerce", "Grocery", "Food Delivery", "Parcel"];

    const workhandleChange = (e) => {
        const { checked, name } = e.target;

        if (checked) { formik.setFieldValue("workType", [...formik.values.workType, name]); }
        else { formik.setFieldValue("workType", formik.values.workType.filter((v) => v !== name)); }

    }

    return (

        <form className="rForm" onSubmit={formik.handleSubmit}>

            <div className="rFormTitle">
                Enter your details below:
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">Name</span>
                <input type="text" name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                {formik.errors.name && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.name}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">Email</span>
                <input type="email" name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                {formik.errors.email && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.email}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">Aadhaar No.</span>
                <input type="text" name="aadhaar"
                    onChange={formik.handleChange}
                    value={formik.values.aadhaar}
                />
                {formik.errors.aadhaar && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.aadhaar}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">Contact Number</span>
                <input type="text" name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />
                {formik.errors.phone && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.phone}</span>}
            </div>

            <div className="rFormCity">
                <span id="rFormNameTitle">City</span>
                <select name="city" id=""
                    onChange={formik.handleChange}
                    value={formik.values.city}
                >
                    <option value="" hidden></option>
                    <option value="FARIDABAD">FARIDABAD</option>
                    <option value="GURGAON">GURGAON</option>
                </select>
                {formik.errors.city && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.city}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">What is your preferred Location?</span>
                <input type="text" name="preferredWorkLocations"
                    onChange={formik.handleChange}
                />
                {formik.errors.preferredWorkLocations && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.preferredWorkLocations}</span>}
            </div>

            <div className="rFormGender">
                <span id="rFormGenderTitle">Gender</span>
                <div className="rFormGenderInput" onChange={formik.handleChange}>
                    <div className="" >
                        <input type="radio" name="gender" id="rRadioMale" value="Male" />
                        <label htmlFor="rRadioMale">Male</label>
                    </div>
                    <div className="">
                        <input type="radio" name="gender" id="rRadioFemaleMale" value="Female" />
                        <label htmlFor="rRadioFemaleMale">Female</label>
                    </div>
                </div>
                {formik.errors.gender && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.gender}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">What is your Date of Birth?</span>
                <input type="date" name="DOB"
                    onChange={formik.handleChange}
                    value={formik.values.DOB}
                />
                {formik.errors.DOB && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.DOB}</span>}
            </div>

            <div className="rFormGender">
                <span id="rFormGenderTitle">What work you want to do?</span>
                <div className="rFormGenderInput"
                >
                    {
                        workType.map((work, i) => {
                            return (
                                <div className="" key={i}>
                                    <input type="checkbox"
                                        name={work}
                                        onChange={workhandleChange}
                                        checked={formik.values.workType.includes(work)}
                                    />
                                    <label>{work}</label>
                                </div>
                            )
                        })}
                </div>
                {formik.errors.workType && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.workType}</span>}
            </div>

            <div className="rFormGender">
                <span id="rFormGenderTitle">What is the type of Vehicle you have?</span>
                <div className="rFormGenderInput" onChange={formik.handleChange}>
                    <div className="">
                        <input type="radio" name="vehicle" value="Bike - EV" />
                        <label>Bike - EV</label>
                    </div>
                    <div className="">
                        <input type="radio" name="vehicle" value="Bike - Petrol" />
                        <label>Bike - Petrol</label>
                    </div>
                    <div className="">
                        <input type="radio" name="vehicle" value="eeco/tata ace" />
                        <label>Eeco / Tata ace</label>
                    </div>
                    <div className="">
                        <input type="radio" name="vehicle" value="Cycle" />
                        <label>Cycle</label>
                    </div>
                    <div className="">
                        <input type="radio" name="vehicle" value="No Vehicle" />
                        <label>No Vehicle</label>
                    </div>
                </div>
                {formik.errors.vehicle && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.vehicle}</span>}
            </div>

            <div className="rFormGender">
                <span id="rFormGenderTitle">What is your Licence Type</span>
                <div className="rFormGenderInput" onChange={formik.handleChange}>
                    <div className="">
                        <input type="radio" name="licenceType" value="2W" />
                        <label>2 Wheeler</label>
                    </div>
                    <div className="">
                        <input type="radio" name="licenceType" value="4W" />
                        <label>4 Wheeler</label>
                    </div>
                    <div className="">
                        <input type="radio" name="licenceType" value="HV" />
                        <label>HV</label>
                    </div>
                    <div className="">
                        <input type="radio" name="licenceType" value="NO LICENCE" />
                        <label>Don't have Licence</label>
                    </div>
                </div>
                {formik.errors.licenceType && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.licenceType}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">License Number</span>
                <input type="text" name="licenceNumber"
                    onChange={formik.handleChange}
                />
                {formik.errors.licenceNumber && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.licenceNumber}</span>}
            </div>

            <div className="rFormName">
                <span id="rFormNameTitle">Address</span>
                <input type="text" name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                />
                {formik.errors.address && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.address}</span>}
            </div>

            <div className="rFormBtn">
                <button id="btn2" type="submit">Submit</button>
                {errorMessage && <span style={{ margin: "0 1rem", color: "red" }}>{errorMessage}</span>}
                {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
            </div>

        </form>
    )
}

export default RiderFormComp;