import "./CreateJob.scss"

function CreateJob() {
    return (
        <form>
            <div className="crtjob-container">
                <div className="crtjob">
                    <h2>Create Job</h2>
                    <div className="crtjob-main">
                        <div className="textInput">
                            <div className="txtInput-label">Job Title</div>
                            <input type="text" />
                        </div>
                        <div className="textInput">
                            <div className="txtInput-label">Job Salary</div>
                            <input type="text" />
                        </div>
                        <div className="textInput">
                            <div className="txtInput-label">Job Description</div>
                            <textarea rows="20" />
                        </div>
                    </div>
                    <button id="btn2" style={{ padding: "6px 4px" }} disabled>Submit</button>
                </div>
            </div>
        </form>
    )

}

export default CreateJob;
