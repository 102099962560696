import { useState } from "react"
import "./styles/FAQ.css"

function FAQ({ faqs }) {
    const [toggleItems, setToggleItems] = useState([]);

    const toggle = i => {
        const showItems = toggleItems.slice();

        if (showItems.includes(i)) {
            showItems.splice(showItems.indexOf(i), 1);
            setToggleItems(showItems);
        } else {
            showItems.push(i);
            setToggleItems(showItems);
        }
    }

    return (
        <div className="faq">
            <div className="faqContainer">
                <div className="faqHeader">FAQ's</div>
                {
                    faqs.map((item, i) => {
                        return (
                            <div className="faqItem" onClick={() => toggle(i)} key={i}>
                                <div className="faqTop">
                                    <div className="faqTitle">{item.title}</div>
                                    <i className="fa-solid fa-chevron-down"></i>
                                </div>
                                {
                                    toggleItems.includes(i) &&
                                    <div className="faqDesc">{item.description}</div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FAQ;
