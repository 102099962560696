import "./styles/Warehouse.css"
import Topbar from "../../components/Topbar"
import Footer from "../../components/Footer"
import WareHouseComp from "../../components/WareHouseComp"
// import FAQ from "../components/FAQ"

function Warehouse() {

    // const faqs = [
    //     {
    //         title: "Where can I watch?",
    //         description: "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
    //     },
    //     {
    //         title: "Where can I watch?",
    //         description: "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
    //     }
    // ]

    return (
        <>
            <Topbar />
            <div className="warehousee" id="warehouse-top">
                <div className="warehouseeMain">
                    <div className="warehouseeLeft">
                        <div className="warehouseeLeftTitle">
                            Rent your <span style={{ color: "#00B050" }}>WAREHOUSE</span> and earn money
                        </div>
                        <div className="warehouseeLeftDesc">
                            Earn upto 5 lacs per month.
                        </div>
                        <div className="warehouseeImg">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_205.png`} alt="" />
                        </div>
                    </div>
                    <div className="warehouseeRight">
                        <WareHouseComp />
                    </div>
                </div>
                <div className="partner">
                    <div className="partnerTitle">
                        All about our <span style={{ color: "#00B050" }}>Partnership</span>
                    </div>
                    <div className="partnerItems">
                        <div className="partnerItem" id="partnerCostItem">
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_206.png`} alt="" />
                                </div>
                            </div>
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    How it <span style={{ color: "#00B050" }}>works?</span>
                                </div>
                                <div className="partnerRightDesc">
                                    It is a seamless and swift process where delivery personnel of franchisee partners pick up shipments from our allocated points and deliver them to the customer's doorstep.
                                </div>
                            </div>
                        </div>
                        <div className="partnerItem">
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    What <span style={{ color: "#00B050" }}>we do?</span>
                                </div>
                                <div className="partnerRightDesc">
                                    Send33 not only distributes the e-commerce load, generates revenue & provides training and monitors the performance of the delivery partners of franchisee holders, franchisees are also provided with a training program and given access to our Employee Resource Planning portal.
                                </div>
                            </div>
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_199.png`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="partnerItem" id="partnerCostItem">
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_197.png`} alt="" />
                                </div>
                            </div>
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    What costs are incurred by the franchisee?
                                </div>
                                <div className="partnerRightDesc">
                                    Send33 not only distributes the e-commerce load, generates revenue & provides training and monitors the performance of the delivery partners of franchisee holders, franchisees are also provided with a training program and given access to our Employee Resource Planning portal.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grow">
                    <div className="growContainer">
                        <div className="growLeft">
                            <div className="growLeftTitle">
                                Grow your business with Send33
                            </div>
                            <div className="growLeftDesc">
                                Channel your growth with India's leading Fulfilment and Delivery brand into your business with Send33 Partner Program.
                            </div>
                            <a href="#warehouse-top">
                                <button id="btn2" style={{ width: "200px", fontSize: "16px" }}>Become a Send33 Partner</button>
                            </a>
                        </div>
                        <div className="growRight">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/New_employee1.png`} alt="" />
                        </div>
                    </div>
                </div>
                {/* <FAQ faqs={faqs} /> */}
            </div>
            <Footer />
        </>
    )
}

export default Warehouse;
