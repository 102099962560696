import "./styles/Rider.css";
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import RiderFormComp from "../../components/RiderFormComp";
import FAQ from "../../components/FAQ";
import ImageComp from "../../components/ImageComp"

function Rider() {

    const faqs = [
        {
            title: "What Documents are needed to get Work?",
            description: "Basic Details such as AADHAAR card, PAN card, and Bank Account proof are mandatory for KYC."
        },
        {
            title: "Is there any Onboarding Fee?",
            description: "No. However, there could be certain Brands which requires you to furnish Police Verification which comes at a Nominal fee."
        },
        {
            title: "Can I get work in my Location?",
            description: "Yes. We always prefer to get you work nearest to your current location."
        },
        {
            title: "When do I get Paid?",
            description: "This depends on the Brands where we get you placed. This could vary from once a week, once in 15 days, once in a month."
        },
        {
            title: "How is my Payout Calculated?",
            description: "Your payout could be Fixed Guaranteed or on per Order Basis. The more you deliver, the more you earn."
        },
        {
            title: "Do I get Incentives?",
            description: "Yes, you may get Extra pay for Extra Orders, Extra Kms traveled and for Working on all Weekends."
        },
        {
            title: "How many orders I have to Deliver?",
            description: "You may be delivering 15-20 orders a day with a Grocery brand and 50-100 orders a day with an E-Commerce brand."
        },
        {
            title: "What if I dont own a Vehicle, can i get an EV from you?",
            description: "Yes. We can help you get a brand new EV on easy rental scheme. Click here to submit your request."
        },
        {
            title: "How many Kms I will have to drive in a Day?",
            description: "You will be driving 80-120 Kms in a Day as you get short distance deliveries in the range of 3-8 Kms only."
        },
    ]

    return (
        <>
            <Topbar />
            <div className="riderr">
                <div className="riderrMain">
                    <div className="riderrLeft">
                        <div className="riderrLeftTitle">
                            Become a <span style={{ color: "#00B050" }}>Delivery
                                Partner</span> and start earning
                        </div>
                        <div className="riderrLeftDesc">
                            Earn upto ₹30,000 per month.
                        </div>
                        <div className="riderrLeftImg">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/delive.png`} alt="" />
                        </div>
                    </div>
                    <div className="riderrRight">
                        <RiderFormComp />
                    </div>
                </div>
                <div className="riderrOptions">
                    <div className="riderrOptonsTitle">Join us and choose when and how to work</div>
                    <div className="riderrOptionsItems">
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">You’re in charge</div>
                                <div className="riderOptionDesc">
                                    There is no one to report to, you are your own boss.
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group2.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">You pick the time</div>
                                <div className="riderOptionDesc">
                                    Log in and log out anytime you want. We are live 24*7
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group3.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">Earn as you go</div>
                                <div className="riderOptionDesc">
                                    Earn upto 30k monthly, best compensation in the market.
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group88.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">Your ride, your city</div>
                                <div className="riderOptionDesc">
                                    Run different orders ranging from deliveries to rides
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="howw">
                    <div className="howwHeader">How it works?</div>
                    <div className="howwContainer">
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_183.png`} alt="" />
                            <span className="howwTitle">Get registered with us</span>
                            <div className="howwDes">
                                Upload your PAN and AADHAR to get registered
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_184.png`} alt="" />
                            <span className="howwTitle">Complete training</span>
                            <div className="howwDes">
                                Get our training order from our nearest center. Deliver it and complete your training
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_185.png`} alt="" />
                            <span className="howwTitle">Start Earning</span>
                            <div className="howwDes">
                                Book a delivery slot of your choice to go online and get your first order. Get paid for every order.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="homeBrands">
                    <div className="brandTitle">Brands that trust <span style={{ color: "#00B050" }}>Send33</span></div>
                    <div className="brandLogos">
                        <ImageComp img={"image_28.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_30.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"BoiveadLogo.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"fandp.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_29.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"mm_1.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                    </div>
                </div>

                <FAQ faqs={faqs} />
            </div>
            <Footer />
        </>
    )
}

export default Rider;
