import "./Register.css";
import { useFormik } from "formik";
import { useState, useContext } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';
import { AuthContext } from "../../../context/AuthContext";

function Register() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const { dispatch } = useContext(AuthContext);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid Email").required("Required"),
            password: Yup.string().required("Required")
        }),

        onSubmit: async (values) => {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/register`, values)
                .then(resp => {
                    setLoading(false);
                    sessionStorage.setItem("accessToken", resp.data.tokens.access.token);
                    localStorage.setItem("refreshToken", resp.data.tokens.refresh.token);
                    dispatch({
                        type: "Login",
                        payload: resp.data.user
                    })
                    navigate("/partner-verify");
                }).catch(err => {
                    setErrorMessage(err.response.data.message);
                    setLoading(false);
                    setError(!error);
                })
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} >
            <div className="login">
                <div className="login-container">
                    <div className="loginTop">
                        <span className="LoginHeader"><span style={{ color: "#00B050" }}>Rider</span> Register</span>
                    </div>
                    <div className="loginBottom">
                        <div className="loginEmail">
                            <span className="InptTitle">Name</span>
                            <input type="name" name="name" onChange={formik.handleChange} />
                            {formik.errors.name && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.name}</span>}
                        </div>
                        <div className="loginEmail">
                            <span className="InptTitle">Email</span>
                            <input type="email" name="email" onChange={formik.handleChange} />
                            {formik.errors.email && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.email}</span>}
                        </div>
                        <div className="loginEmail">
                            <span className="InptTitle">Password</span>
                            <input type="password" name="password" autoComplete="true" onChange={formik.handleChange} />
                            {formik.errors.password && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.password}</span>}
                        </div>
                    </div>
                    <div className="login-btns">
                        <button type="submit" className="loginBtn" id="btn4" style={{ margin: "0 8px" }}>Register</button>
                        <a href="/partner-login">
                            <span className="loginBtn" id="btn5">Login</span>
                        </a>
                    </div>
                    {errorMessage && <span style={{ margin: "0 1rem", color: "red" }}>{errorMessage}</span>}
                    {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                </div>
            </div>
        </form>

    )
}

export default Register;
