import "./styles/SliderImg.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function SliderImg() {

    return (
        <div className="sliderImg">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={5000}
                transitionDuration={500}
            >
                <div className="slidImg">
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/slider_1.png`} alt="" />
                </div>
                <div className="slidImg">
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/slider_2.png`} alt="" />
                </div>
                <div className="slidImg">
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/slider_3.png`} alt="" />
                </div>
                <div className="slidImg">
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/slider_4.png`} alt="" />
                </div>
                <div className="slidImg">
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/slider_5.png`} alt="" />
                </div>
            </Carousel>
        </div>
    )
}

export default SliderImg;