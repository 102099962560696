const states = [
    // "Andhra Pradesh",
    // "Arunachal Pradesh",
    // "Assam",
    // "Bihar",
    // "Chhattisgarh",
    // "Goa",
    // "Gujarat",
    // "Haryana",
    // "Himachal Pradesh",
    // "Jammu and Kashmir",
    // "Jharkhand",
    // "Karnataka",
    // "Kerala",
    // "Madhya Pradesh",
    // "Maharashtra",
    // "Manipur",
    // "Meghalaya",
    // "Mizoram",
    // "Nagaland",
    // "Odisha",
    // "Punjab",
    // "Rajasthan",
    // "Sikkim",
    // "Tamil Nadu",
    // "Telangana",
    // "Tripura",
    // "Uttarakhand",
    // "Uttar Pradesh",
    // "West Bengal",
    // "Andaman and Nicobar Islands",
    // "Chandigarh",
    // "Dadra and Nagar Haveli",
    // "Daman and Diu",
    // "Delhi",
    // "Lakshadweep",
    // "Puducherry"
    "Haryana",
    "Delhi",
    "Uttar Pradesh",
    "West Bengal",
    "Punjab",
    "Rajasthan"
]

export default states.sort();