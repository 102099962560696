import "./styles/Privacy.css"
import Topbar from "../../components/Topbar"
import Footer from "../../components/Footer"

function Privacy() {

    return (
        <>
            <Topbar />
            <div className="privacy">
                <div className="privacyHeader">Privacy Policy</div>
                <div className="privacyLastUpdated">Last updated on December 21, 2022.</div>
                <div className="privacyPara">
                    We collect information about you when you visit our site, use our services, or view our online
                    advertisements. The information that we collect and store during normal use of the site is used to
                    monitor use of the site and to help further development of our services. In general, you can visit
                    SEND33 EXPRESS PRIVATE LIMITED (“Send33/Our Company”) website on the Internet
                    without telling us who you are or providing data that personally identifies you. Such use does not
                    result in any personally identifiable data being collected or stored.
                </div>
                <div className="privacySctnTitle">Privacy Statement</div>
                <div className="privacyPara">
                    Protecting your privacy and ensuring that your personal data is held securely is very important to
                    Our Company. We want you to be confident that we are looking after your interests and that is
                    why we have set out our information practices in this Privacy Statement.
                    <br />
                    <br />
                    We do not share your information with third parties, unless we need to share your information to
                    provide service you have requested and we have asked for and obtained your explicit consent.
                    The foregoing notwithstanding, if (1) disclosure was required by law, rule or regulation or was in
                    response to a valid order of a court or authorized agency of government or other legal process
                    and/or; (2) When we find your action on the website violates Our Company’s terms and
                    conditions or any of your usage guidelines for services.
                    <br />
                    <br />
                    This Privacy Statement will apply when you use our website to discover more information about
                    Our Company. This Privacy Statement is applicable to data collected by Our Company.
                    <br />
                    <br />
                    Whilst any application, request or enquiry you make, may be passed or made available to Our
                    Company’s affiliates some of which will be based in countries that do not have data protection
                    laws, all Send33 affiliates are required to comply with an internal Privacy Policy which requires
                    that steps are taken to protect your personal data and for it to only be used for the purpose(s) for
                    which it was submitted.
                    <br />
                    <br />
                    By submitting your personal data through this website, you shall be consenting to it being
                    processed in the manner described above by Send33.
                    <br />
                    <br />
                    If you have submitted personal data through this website and wish us to cease using it for the
                    purposes submitted, please contact us using the contact details given in the appropriate sections
                    of the site.
                </div>
                <div className="privacySctnTitle">Contacting us</div>
                <div className="privacyPara">
                    Send33 regularly reviews its compliance with this Privacy Statement. Please feel free to direct
                    any questions or concerns regarding this Privacy Statement or our treatment of personal data and
                    information by completing our web contact form or by writing to us at the following addresses:
                    <br />
                    <br />
                    Registered Office:
                    <br />
                    UG 06, MGF METROPOLIS MALL MG ROAD,
                    <br />
                    GURGAON
                    <br />
                    Haryana 122002
                    <br />
                    India
                </div>
                <div className="privacySctnTitle">Changes to this Policy</div>
                <div className="privacyPara">
                    Send33 may edit this policy from time to time. If we make substantial changes, we will notify
                    you by posting a prominent announcement on this website.
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy;