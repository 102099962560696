import { useState, useEffect } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { Spin, Button, Modal } from 'antd';
import "./styles/PartnerDocs.scss";

function PartnerDocs() {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [docUrl, setDocUrl] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (docUrl) => {
        setDocUrl(docUrl)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`)
            .then(resp => {
                setUser({ ...resp.data, ...JSON.parse(localStorage.getItem("user")) });
                setLoading(false);
            })
            .catch(err => {
                console.log(err.response.data.message);
                setLoading(false)
            })
    }, [])

    return (
        <div className="partner-docs-container">
            <Modal title="Partner Documents" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <img src={docUrl} alt="" className="partner-dash-doc-img" />
            </Modal>
            <div className="partner-docs-top" style={{ fontWeight: "600" }}>Partner Documents</div>
            <br />
            {loading && <Spin />}
            {!loading &&
                <>
                    <div className="partner-docs-main">
                        <div className="partner-docs-main-item1">
                            <div style={{ fontSize: "1.2rem", margin: "1rem 0", fontWeight: "600" }}>Personal Information</div>
                            <div className="partner-docs-item-details">
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Name :</span>
                                    <span className="optn-value">{user?.name}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Email :</span>
                                    <span className="optn-value">{user?.email}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Phone No. :</span>
                                    <span className="optn-value">{user?.phone}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Address :</span>
                                    <span className="optn-value">{user?.currentAddress}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">City :</span>
                                    <span className="optn-value">{user?.city}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">State :</span>
                                    <span className="optn-value">{user?.state}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="partner-docs-main-item1">
                            <div style={{ fontSize: "1.2rem", margin: "1rem 0", fontWeight: "600" }}>Documents</div>
                            <div className="partner-docs-uplds">
                                {
                                    user?.adhaarUrlFront &&
                                    <div className="partner-profile-item">
                                        <Button type="primary" onClick={() => showModal(user?.adhaarUrlFront)}>Aadhaar Front</Button>
                                    </div>
                                }
                                {
                                    user?.adhaarUrlBack &&
                                    <div className="partner-profile-item">
                                        <Button type="primary" onClick={() => showModal(user?.adhaarUrlBack)}>Aadhaar Back</Button>
                                    </div>
                                }
                                {
                                    user?.panUrl &&
                                    <div className="partner-profile-item">
                                        <Button type="primary" onClick={() => showModal(user?.panUrl)}>PAN</Button>
                                    </div>
                                }
                                {
                                    user?.drivingLicenseUrl &&
                                    <div className="partner-profile-item">
                                        <span className="partner-profile-label">Licence</span>
                                        <Button type="primary" onClick={() => showModal(user?.drivingLicenseUrl)}>Licence</Button>
                                    </div>
                                }
                            </div>
                            <hr />
                        </div>
                        <div className="partner-docs-main-item1">
                            <div style={{ fontSize: "1.2rem", margin: "1rem 0", fontWeight: "600" }}>Vehicle Information</div>
                            <div className="partner-docs-item-details">
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Vehicle Type :</span>
                                    <span className="optn-value">{user?.vehicleType}</span>
                                </div>
                                <div className="partner-docs-item-detail">
                                    <span className="optn-head">Licence Number :</span>
                                    <span className="optn-value">{user?.drivingLicense}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default PartnerDocs;
