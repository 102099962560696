import "./styles/WareHouseComp.css"
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Spin } from 'antd';

function FulfillFormComp() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            company: "",
            ordersPerDay: "",
            weightPerOrder: "",
            city: "",
            website: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid Email").required("Required"),
            phone: Yup.string().max(10).min(10).required("Required"),
            company: Yup.string().required("Required"),
            ordersPerDay: Yup.string().required("Required"),
            weightPerOrder: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            website: Yup.string().required("Required"),
        }),

        onSubmit: async (values) => {
            setLoading(true);
            const details = {
                ...values,
                phone: { "countryCode": "+91", "number": values.phone }
            }

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/partner-brand`, details)
                .then(resp => {
                    setLoading(false);
                    navigate("/success");
                }).catch(err => {
                    setErrorMessage(err.response.data.message);
                    setLoading(false);
                    setError(!error);
                })
        }

    });

    return (
        <div className="">
            <form onSubmit={formik.handleSubmit} className="rForm">
                <div className="rFormTitle">
                    Enter your details below:
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Name</span>
                    <input type="text" name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />
                    {formik.errors.name && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.name}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Email</span>
                    <input type="email" name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    {formik.errors.email && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.email}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Contact Number</span>
                    <input type="text" name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                    />
                    {formik.errors.phone && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.phone}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Company</span>
                    <input type="text" name="company"
                        onChange={formik.handleChange}
                        value={formik.values.company}
                    />
                    {formik.errors.company && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.company}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Orders per day</span>
                    <input type="text" name="ordersPerDay"
                        onChange={formik.handleChange}
                        value={formik.values.ordersPerDay}
                    />
                    {formik.errors.ordersPerDay && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.ordersPerDay}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Weight per order (grams)</span>
                    <input type="text" name="weightPerOrder"
                        onChange={formik.handleChange}
                        value={formik.values.weightPerOrder}
                    />
                    {formik.errors.weightPerOrder && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.weightPerOrder}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">City</span>
                    <input type="text" name="city"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                    />
                    {formik.errors.city && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.city}</span>}
                </div>

                <div className="rFormName">
                    <span id="rFormNameTitle">Website</span>
                    <input type="text" name="website"
                        onChange={formik.handleChange}
                        value={formik.values.website}
                    />
                    {formik.errors.website && <span style={{ color: "red", fontSize: "0.9rem" }}>{formik.errors.website}</span>}
                </div>

                <div className="rFormBtn">
                    <button id="btn2" type="submit">Submit</button>
                    {errorMessage && <span style={{ margin: "0 1rem", color: "red" }}>{errorMessage}</span>}
                    {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                </div>
            </form>
        </div>
    )
}

export default FulfillFormComp;