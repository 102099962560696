import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

function Success() {
    return (

        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Our Team will Contact you soon!!
                <Link to="/partner">
                    <strong> Go Back</strong>
                </Link>
            </Alert>
        </Stack>
    )
}

export default Success;