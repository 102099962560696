import "./AdminDropDown.scss"
import { Link } from "react-router-dom"

function AdminDropDown({ title, options }) {

    return (
        <div className="sidebar-dropdown">
            <span className="sidebar-dropdown-title">{title}</span>
            <div className="sidebar-dropdown-options">
                {
                    options.map((option, i) => {
                        return (
                            <Link to={`/admin/${option.link}`} key={i}>
                                <div className="sidebar-dropdown-option">{option.option} </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default AdminDropDown;