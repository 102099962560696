import "./styles/Profile.scss";
import moment from 'moment';

function Profile({ user }) {

    return (
        <>
            <div className="partner-docs-main">
                <div className="partner-docs-main-item1">
                    <div style={{ fontSize: "1.2rem", margin: "1rem 0", fontWeight: "600" }}>Personal Information</div>
                    <div className="partner-docs-item-details">
                        <div className="partner-docs-item-detail">
                            <span className="optn-head">Name :</span>
                            <span className="optn-value">{user?.name}</span>
                        </div>
                        <div className="partner-docs-item-detail">
                            <span className="optn-head">Email :</span>
                            <span className="optn-value">{user?.email}</span>
                        </div>
                        <div className="partner-docs-item-detail">
                            <span className="optn-head">Phone No. :</span>
                            <span className="optn-value">{user?.phone}</span>
                        </div>
                        <div className="partner-docs-item-detail">
                            <span className="optn-head">Address :</span>
                            <span className="optn-value">{user?.currentAddress}</span>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="partner-docs-main-item1">
                    <div style={{ fontSize: "1.2rem", margin: "1rem 0", fontWeight: "600" }}>Work Analytics</div>
                    <div className="partner-prof-analytics">
                        <div className="partner-prof-analytic-card" style={{ background: "#F5D7C9" }}>
                            <span className="partner-prof-analytic-card-title">0</span>
                            <span className="partner-prof-analytic-card-desc">Orders Delivered</span>
                        </div>
                        <div className="partner-prof-analytic-card" style={{ background: "#CCEBE9" }}>
                            <span className="partner-prof-analytic-card-title" >0</span>
                            <span className="partner-prof-analytic-card-desc">Targets Completed</span>
                        </div>
                        <div className="partner-prof-analytic-card" style={{ background: "#EFEABD" }}>
                            <span className="partner-prof-analytic-card-title" > {moment().diff(moment(user.createdAt), 'days')}</span>
                            <span className="partner-prof-analytic-card-desc">Days with Send33</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;
