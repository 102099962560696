import { Table, Tag, Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { CSVLink } from "react-csv";

function Partner() {
    const [data, setData] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    useEffect(() => {
        setLoading(true)
        CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/rider-profiles`)
            .then(resp => {
                setTotalPages(resp.data.totalResults)
                setData(resp.data.results)
                setFilteredData(resp.data.results)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response.data);
                setLoading(false)
            })
    }, [page])

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (_, record) => {
                return (
                    <>
                        {moment(record.createdAt).format("DD/MM/YY")}
                        <br />
                        {moment(record.createdAt).format("h:mm A")}
                    </>
                )
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            title: 'Current Address',
            dataIndex: 'currentAddress',
        },
        {
            title: 'Permanent Address',
            dataIndex: 'permanentAddress',
        },
        {
            title: 'City',
            dataIndex: 'city',
        },
        {
            title: 'State',
            dataIndex: 'state',
        },
        {
            title: 'Aadhaar',
            dataIndex: 'adhaar',
            render: (_, record) => {
                return (
                    <>
                        <span>{_}</span>
                        {
                            record.adhaar &&
                            <div className="">
                                <Tag color={"green"} style={{ cursor: "pointer" }} onClick={() => showModal(record.adhaarUrlFront, _)}>FRONT</Tag>
                                <Tag color={"green"} style={{ cursor: "pointer" }} onClick={() => showModal(record.adhaarUrlBack, _)}>BACK</Tag>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            title: 'PAN',
            dataIndex: 'pan',
            render: (_, record) => {
                return (
                    <>
                        <span>{_}</span>
                        {
                            record.panUrl &&
                            <div className="" onClick={() => showModal(record.panUrl, _)}>
                                <Tag color={"volcano"} style={{ cursor: "pointer" }}>PAN</Tag>
                            </div>
                        }
                    </>
                )
            }

        },
        {
            title: 'Vehicle Type',
            dataIndex: 'vehicleType',
        },
        {
            title: 'Licence',
            dataIndex: 'drivingLicense',
            render: (_, record) => {
                return (
                    <>
                        <span>{_}</span>
                        {
                            record.drivingLicenseUrl &&
                            <div className="" onClick={() => showModal(record.drivingLicenseUrl, _)} style={{ cursor: "pointer" }}>
                                <Tag color={"geekblue"}>Licence</Tag>
                            </div>
                        }
                    </>
                )
            }
        },
    ]

    const headers = [
        {
            label: 'CreatedAt',
            key: 'createdAt',
        },
        {
            label: 'Phone',
            key: 'phone',
        },
        {
            label: 'Current Address',
            key: 'currentAddress',
        },
        {
            label: 'Permanent Address',
            key: "permanentAddress",
        },
        {
            label: 'City',
            key: 'city',
        },
        {
            label: 'State',
            key: 'state',
        },
        {
            label: 'Aadhaar',
            key: 'adhaar',
        },
        {
            label: 'PAN',
            key: 'pan',
        },
        {
            label: 'Vehicle Type',
            key: 'vehicleType',
        },
        {
            label: 'Licence',
            key: 'drivingLicense',
        },
    ]
    const [docUrl, setDocUrl] = useState("");
    const [docModalTitle, setDocModalTitle] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (docUrl, docModalTitle) => {
        setDocUrl(docUrl)
        setDocModalTitle(docModalTitle)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setDocUrl("")
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setDocUrl("")
    };

    return (
        <>
            <Modal title={docModalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <img src={docUrl} alt="" className="partner-dash-doc-img" />
            </Modal>
            <h2>Partner Documents</h2>
            <div className="admin-options">
                <div className="dash-table-search">
                    <input type="text"
                        placeholder="Search..."
                        onChange={e => {
                            const currValue = e.target.value.toLowerCase();
                            const filteredData_ = data.filter(entry =>
                                entry.phone?.toLowerCase().includes(currValue) ||
                                entry.currentAddress?.toLowerCase().includes(currValue) ||
                                entry.permanentAddress?.toLowerCase().includes(currValue) ||
                                entry.city?.toLowerCase().includes(currValue) ||
                                entry.state?.toLowerCase().includes(currValue) ||
                                entry.adhaar?.toLowerCase().includes(currValue) ||
                                entry.pan?.toLowerCase().includes(currValue) ||
                                entry.drivingLicense?.toLowerCase().includes(currValue)
                            );
                            setFilteredData(filteredData_);
                        }}
                    />
                </div>
                <CSVLink filename={"PartnerDocs.csv"} data={data} headers={headers}>
                    <button id="btn3" style={{ padding: "4px 8px" }}>EXPORT</button>
                </CSVLink>
            </div>

            <div className="dash-table">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{ position: ["topLeft"], pageSize: 10, total: totalPages }}
                    onChange={(e) => setPage(e.current)}
                    loading={loading}
                    rowKey="id" />
            </div>
        </>
    )
}

export default Partner;