import "./styles/Home.css"
import Topbar from '../../components/Topbar';
import SliderImg from "../../components/SliderImg";
import Footer from "../../components/Footer";
import HowSlide from "../../components/HowSlide";
import ImageComp from "../../components/ImageComp";

function Home() {

    const worksArr = [
        {
            title: "Real-Time Fulfilment under ₹33/- order",
            desc: "Send33 is Convenient and Low Cost way of enabling Quick-Commerce for anything that is sold Online through our Tech-Enabled Distribution, Fulfilment and Delivery network.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/how_img.png`
        },
        {
            title: "Happy Customer",
            desc: "We are the most Convenient and Cost effective way of Storage, Fulfilment and Delivery to your customers. Come to us to and score the competitive edge to build long term value with your customers.",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group-1.png`
        },
        {
            title: "Set up a Dark Store with us",
            desc: "Connect your online store seamlessly with Send33, have an inventory storage under ₹33/-",
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group.png`
        }
    ]

    return (
        <>
            {/* <---------------- TOPBAR ----------------> */}

            <Topbar />

            {/* <---------------- HOME MAIN ----------------> */}

            <div className="home">

                <div className="homeMain">
                    <div className="homeMainTop">
                        <div className="homeMainTitle">
                            India’s 1st and only Brand that commits Delivery in <span style={{ color: "#00B050" }}>33 mins!</span>
                        </div>
                        <div className="homeMainDesc">
                            Express Delivery | Convenient | Low Cost |
                            Customer Delight | Full-Stack Tech
                        </div>
                        <a href="/fulfill">
                            <button id="btn2">Get Started</button>
                        </a>
                    </div>
                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/homeMain.png`} alt="" id="homeDelivImg" />
                    <div className="homeMainImages">
                        <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/homeMain.png`} alt="" id="homeDelivImg" />
                        <div className="homeMainImg" id="wareHouse">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_168.png`} alt="" id="home-main-img" />
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_169.png`} alt="" id="connect1" />
                            <span>Provision of Dark Stores</span>
                        </div>
                        <div className="homeMainImg" id="exper">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_161.png`} alt="" id="home-main-img" />
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_170.png`} alt="" id="connect2" />
                            <span>Fulfilment Experiance</span>
                        </div>
                        <div className="homeMainImg" id="delivery">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_162-1.png`} alt="" id="home-main-img" />
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_171.png`} alt="" id="connect3" />
                            <span>Delivery in 33 mins</span>
                        </div>
                    </div>
                </div>

                {/* <---------------- Corousel ----------------> */}

                <div className="slider_">
                    <SliderImg />
                </div>

                {/* <---------------- HOME Store Section ----------------> */}

                <div className="store">
                    <div className="storeRight">
                        <ImageComp img={"store.png"} width={600} maxWidth={"100%"} />
                    </div>
                    <div className="mapLeft">
                        <span className="mapTitle">Network of <span style={{ color: "#00B050" }}>DARK STORES</span></span>
                        <span className="mapDesc">Deep network of dark stores</span>
                        <span className="mapLoc">Dark stores are strategically located all over India, come be a part of our journey and rent warehouse,
                            earn easy money upto 5 lacs. </span>
                        <div className="mapBtns">
                            <a href="/fulfill">
                                <button id="btn2">Apply Now</button>
                            </a >
                        </div>
                    </div>
                </div>

                {/* <---------------- HOME Map Section ----------------> */}

                <div className="map">
                    <div className="mapLeft">
                        <span className="mapTitle">LIVE in <br /><span style={{ color: "#00B050" }}>6 Cities </span>and<br /> growing more</span>
                        <span className="mapDesc">Launch New Cities with us within <span style={{ color: "#00B050" }}>33 hours</span></span>
                        <span className="mapLoc">Delhi | Gurgaon| Faridabad | Kolkata | Jaipur | Noida</span>
                        <div className="mapBtns">
                            <a href="/fulfill">
                                <button id="btn2">Get Started</button>
                            </a>
                            <a href="#howSection"><button id="btn3" style={{ width: "150px" }}>How it works?</button></a>
                        </div>
                    </div>
                    <div className="mapRight">
                        <ImageComp img={"map.png"} width={500} maxWidth={"100%"} />
                    </div>
                </div>

                {/* <---------------- HOME Rider Section ----------------> */}

                <div className="rider">
                    <div className="mapLeft" style={{ alignSelf: "center" }}>
                        <span className="mapTitle">Become a <span style={{ color: "#00B050" }}>Send33 Rider</span></span>
                        <span className="mapDesc">Earn upto Rs 30,000 a month</span>
                        <span className="mapLoc">At Send33 we believe in creating employement,
                            become a delivery partner, flexible work hours, earn extra money and be part of send33 family
                        </span>
                        <div className="mapBtns">
                            <a href="/rider">
                                <button id="btn2">Apply Now</button>
                            </a>
                        </div>
                    </div>
                    <div className="partnerRight">
                        <ImageComp img={"Group_132.png"} width={600} maxWidth={"100%"} />
                    </div>
                </div>

                {/* <---------------- HOME Warehouse Section ----------------> */}

                <div className="whouse">
                    <div className="whouseRight">
                        <ImageComp img={"warehouse.png"} width={600} maxWidth={"100%"} />
                    </div>
                    <div className="mapLeft">
                        <span className="mapTitle">Rent your <span style={{ color: "#00B050" }}>Warehouse</span></span>
                        <span className="mapDesc">Earn upto 5 lacs every month</span>
                        <span className="mapLoc">At Send33 we have a deep network of dark stores
                            which are strategically located all over India, rent
                            warehouse to us and make best use of it by earning
                            easy money.
                        </span>
                        <div className="mapBtns">
                            <a href={"/warehouse"}>
                                <button id="btn2">Apply Now</button>
                            </a>
                            <a href="#howSection"><button id="btn3" style={{ width: "150px" }}>How it works?</button></a>
                        </div>
                    </div>
                </div>

                {/* <---------------- HOME Brands Section ----------------> */}

                <div className="homeBrands">
                    <div className="brandTitle">Brands that trust <span style={{ color: "#00B050" }}>Send33</span></div>
                    <div className="brandLogos">
                        <ImageComp img={"image_28.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_30.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"BoiveadLogo.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"fandp.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_29.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"mm_1.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                    </div>
                </div>

                {/* <---------------- HOME How Section ----------------> */}

                <div id="howSection">
                    <div className="howHeader">How it <span style={{ color: "#00B050" }}>works?</span></div>
                    <HowSlide howArr={worksArr} />
                </div>

                {/* <---------------- HOME Feature Section ----------------> */}

                <div className="homeFeatureContainer">
                    <div className="homeFeature">
                        <div className="featureItem">
                            <span className="featureItemTitle">99.9%</span>
                            <span className="featureItemDesc">Fulfillment Accuracy</span>
                        </div>
                        <div className="featureItem">
                            <span className="featureItemTitle">40%</span>
                            <span className="featureItemDesc">Fulfillment Cost Savings</span>
                        </div>
                        <div className="featureItem">
                            <span className="featureItemTitle">100%</span>
                            <span className="featureItemDesc">C-SAT Improvement</span>
                        </div>
                        <div className="featureItem">
                            <span className="featureItemTitle">90%</span>
                            <span className="featureItemDesc">Reduction in RTO’s</span>
                        </div>
                    </div>
                </div>

                {/* <---------------- HOME Why Section ----------------> */}

                <div className="why">
                    <div className="whyContainer">
                        <div className="whyTop">
                            <div className="whyHeader">Why <span style={{ color: "#00B050" }}>Send33?</span></div>
                            <div className="whyTopContent">
                                “The first and only brand in India to promise fulfilment,
                                distribution, and delivery within <span style={{ color: "#00B050" }}>33 minutes</span>”
                            </div>
                            <a href={"/fulfill"}>
                                <button id="btn2">Get in touch</button>
                            </a>
                        </div>
                        <div className="whyMain">
                            <div className="whyMainContainer">
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"img_1_1.png"} width={60} height={60} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            Deliver Live in 33 Min
                                        </div>
                                        <div className="whyItemRightDesc">
                                            Send33 gets your products into the hands of Users in 33 Mins!
                                            Our Shared fulfilment and last mile model enables brands to
                                            Deliver 100X faster at a much lower cost, thus making business
                                            model sustainable and scalable.
                                        </div>
                                    </div>
                                </div>
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"img_1_2.png"} width={60} height={60} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            CSAT Guaranteed 90%+
                                        </div>
                                        <div className="whyItemRightDesc">
                                            The only impression about a brand in online space is about how Delivery
                                            Partner makes the customer feel. We value your customers, we empathise
                                            with specific request, we enable long term relationship with the brand.
                                        </div>
                                    </div>
                                </div>
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"mdi_cod.png"} width={60} height={60} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            COD Secure 100%
                                        </div>
                                        <div className="whyItemRightDesc">
                                            Cash on delivery is 100% protected and remitted to Brand’s account
                                            in 3 Working days. Send33 is the most reliable, transparent and
                                            fastest in Cash collection, protection, remittance and reconciliation.
                                        </div>
                                    </div>
                                </div>
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"material-symbols_verified.png"} width={60} height={60} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            {"Returns < 3%"}
                                        </div>
                                        <div className="whyItemRightDesc">
                                            {`We deliver products 100X faster and enable Brand’s Returns
                                            to drop by as high as 90%, thus bringing RTO% down to < 3%.
                                            Product returns are quicky checked and added back to
                                            inventory to be used for new orders.`}
                                        </div>
                                    </div>
                                </div>
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"Vector-2.png"} width={50} height={50} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            Full stack tech with business intelligence & visualisation
                                        </div>
                                        <div className="whyItemRightDesc">
                                            Make informed and data driven decision,
                                            automated reporting and enhanced inventory tracking through our mobile and web apps
                                        </div>
                                    </div>
                                </div>
                                <div className="whyItem">
                                    <div className="whyItemLeft">
                                        <ImageComp img={"Vector-1.png"} width={60} height={60} objectFit={"contain"} />
                                    </div>
                                    <div className="whyItemRight">
                                        <div className="whyItemRightTitle">
                                            Dark Stores
                                        </div>
                                        <div className="whyItemRightDesc">
                                            With dark stores (of different sizes) all across your city- we’ll
                                            get your products fulfilled & delivered to consumers faster than ever!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <---------------- FOOTER ----------------> */}

                <Footer />

            </div>
        </>
    )
}

export default Home;
