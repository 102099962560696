import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext"

function AdminMain() {
    const { user } = useContext(AuthContext);

    return (
        <div className="">
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">
                    <AlertTitle>Welcome back! <b>{user.name}</b></AlertTitle>
                </Alert>
            </Stack>
        </div>
    )
}

export default AdminMain;
