import { Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from "react";
import CustomAxios from "../../CustomAxios/CustomAxios";
import { CSVLink } from "react-csv";

function RiderAdmin() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    useEffect(() => {
        const getFulfillData = async () => {
            setLoading(true)
            const resp = await CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/partner-rider?page=${page}`);
            setTotalPages(resp.data.totalResults)
            setData(resp.data.results)
            setLoading(false)
        }
        getFulfillData();
    }, [page])

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (_, record) => {
                return (
                    <>
                        {moment(record.createdAt).format("DD/MM/YY")}
                        <br />
                        {moment(record.createdAt).format("h:mm A")}
                    </>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone No.',
            dataIndex: ["phone", "number"],
        },
        {
            title: 'City',
            dataIndex: 'city',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: 'Aadhaar',
            dataIndex: 'aadhaar',
        },
        {
            title: 'Preferred Work Locations',
            dataIndex: 'preferredWorkLocations',
            render: (_, record) => record.preferredWorkLocations.toString()
        },
        {
            title: 'Work Type',
            dataIndex: 'workType',
            render: (_, record) => record.workType.toString()
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
        },
        {
            title: 'Licence Type',
            dataIndex: 'licenceType',
        },
        {
            title: 'Licence Number',
            dataIndex: 'licenceNumber',
        }
    ]

    const headers = [
        {
            label: 'CreatedAt',
            key: 'createdAt',
        },
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'Phone No.',
            key: "phone.number",
        },
        {
            label: 'City',
            key: 'city',
        },
        {
            label: 'Address',
            key: 'address',
        },
        {
            label: 'Aadhaar',
            key: 'aadhaar',
        },
        {
            label: 'Preferred Work Locations',
            key: 'preferredWorkLocations',
        },
        {
            label: 'Work Type',
            key: 'workType',
        },
        {
            label: 'Vehicle',
            key: 'vehicle',
        },
        {
            label: 'Licence Type',
            key: 'licenceType',
        },
        {
            label: 'Licence Number',
            key: 'licenceNumber',
        }
    ]

    return (
        <>
            <h2>Delivery Partner</h2>
            <CSVLink filename={"Delivery_Partner.csv"} data={data} headers={headers}>
                <button id="btn3" style={{ padding: "4px 8px" }}>EXPORT</button>
            </CSVLink>
            <div className="dash-table" style={{ overflowX: "scroll" }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{ position: ["topLeft"], pageSize: 10, total: totalPages }}
                    onChange={(e) => setPage(e.current)}
                    loading={loading}
                    rowKey="id" />
            </div>
        </>
    )
}

export default RiderAdmin;