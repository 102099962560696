import "./styles/Footer.css"

function Footer() {

    return (
        <div className="footer">
            <div className="footerLeft">
                <a href="#home-top">
                    <div className="footerLogo">
                        <img src="./img/logo.png" alt="" />
                    </div>
                </a>
                <div className="footerSocial">
                    <a href="https://www.linkedin.com/company/send-33" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://www.facebook.com/people/Send33/100085801385832" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-square-facebook"></i>
                    </a>
                    <a href="https://twitter.com/Send33_India" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-square-twitter"></i>
                    </a>
                </div>
            </div>
            <div className="footerRight">
                <div className="footerMain">
                    <a href="/">
                        <span className="footerMainHeader">Send33</span>
                    </a>
                    <a href="/about">
                        <span className="footerMainTitle">About</span>
                    </a>
                    <a href="/services">
                        <span className="footerMainTitle">Services</span>
                    </a>
                    <span className="footerMainTitle">Terms and Condition</span>
                    <a href="/privacy">
                        <span className="footerMainTitle">Privacy Policy</span>
                    </a>
                    {/* <span className="footerMainTitle">Join Us</span>
                    <Link to="/partner-brand">
                        <span className="footerMainTitle">Send33 for partners</span>
                    </Link>
                    <span className="footerMainTitle">Send33 for business</span> */}
                </div>
                <div className="footerMain">
                    <span className="footerMainHeader">Business</span>
                    <a href="/fulfill">
                        <span className="footerMainTitle">Fullfil and Deliver</span>
                    </a>
                    <a href="/delivery-fleet">
                        <span className="footerMainTitle">Own Delivery Fleet</span>
                    </a>
                </div>
                <div className="footerMain">
                    <span className="footerMainHeader">Earn</span>
                    <a href="/rider" target="_blank">
                        <span className="footerMainTitle">Become Delivery Partner</span>
                    </a>
                    <a href="/warehouse" target="_blank">
                        <span className="footerMainTitle">Rent your Warehouse</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;
