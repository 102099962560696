import "./styles/Services.css"
import Topbar from "../../components/Topbar"
import Footer from "../../components/Footer";
import SliderImg from "../../components/SliderImg"
import WareHouseComp from "../../components/WareHouseComp"
import ImageComp from "../../components/ImageComp";

function Services() {

    return (
        <>
            <Topbar />
            <div className="services">
                <div className="servicesMain">
                    <div className="servicesLeft">
                        <div className="servicesLeftTitle">
                            Network of <span style={{ color: "#00B050" }}>DARK STORES</span>
                        </div>
                        <div className="servicesLeftDesc">
                            Deep network of dark stores in 33 mins
                        </div>
                        <div className="servicesLeftPara">
                            Dark stores are strategically located all over India, come be a part
                            of our journey and rent warhouse, earn easy money upto 5 lacs.
                        </div>
                        <a href="/fulfill">
                            <button id="btn2">Apply Now</button>
                        </a>
                    </div>
                    <div className="servicesRight">
                        <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/store.png`} alt="" />
                    </div>
                </div>
                <SliderImg />
                <div className="rider-main-container">
                    <div className="rider-Main">
                        <div className="rider-Left">
                            <div className="rider-LeftTitle">
                                Become a <span style={{ color: "#00B050" }}>Delivery
                                    Partner</span> and start earning
                            </div>
                            <div className="rider-LeftDesc">
                                Earn upto ₹30,000 per month.
                            </div>
                            <a href="/rider">
                                <button id="btn2" style={{ width: "200px" }}>Become a Send33 Partner</button>
                            </a>
                        </div>
                        <div className="rider-Right" >
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/homeMain.png`} alt="" />
                        </div>
                    </div>
                </div>
                <div className="riderrOptions">
                    <div className="riderrOptonsTitle">Join us and choose when and how to work</div>
                    <div className="riderrOptionsItems">
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">You’re in charge</div>
                                <div className="riderOptionDesc">
                                    There is no one to report to, you are your own boss.
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group2.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">You pick the time</div>
                                <div className="riderOptionDesc">
                                    Log in and log out anytime you want. We are live 24*7
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group3.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">Earn as you go</div>
                                <div className="riderOptionDesc">
                                    Earn upto 30k monthly, best compensation in the market.
                                </div>
                            </div>
                        </div>
                        <div className="riderrOption">
                            <div className="riderrOptionLeft">
                                <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Mask_group88.png`} alt="" />
                            </div>
                            <div className="riderOptionRight">
                                <div className="riderOptionTitle">Your ride, your city</div>
                                <div className="riderOptionDesc">
                                    Run different orders ranging from deliveries to rides
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="howw">
                    <div className="howwHeader">How it works?</div>
                    <div className="howwContainer">
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_183.png`} alt="" />
                            <span className="howwTitle">Get registered with us</span>
                            <div className="howwDes">
                                Upload your PAN and AADHAR to get registered
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_184.png`} alt="" />
                            <span className="howwTitle">Complete training</span>
                            <div className="howwDes">
                                Get our training order from our nearest center. Deliver it and complete your training
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_185.png`} alt="" />
                            <span className="howwTitle">Start Earning</span>
                            <div className="howwDes">
                                Book a delivery slot of your choice to go online and get your first order. Get paid for every order.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="warehouseeMain">
                    <div className="warehouseeLeft">
                        <div className="warehouseeLeftTitle">
                            Rent your <span style={{ color: "#00B050" }}>WAREHOUSE</span> and earn money
                        </div>
                        <div className="warehouseeLeftDesc">
                            Earn upto 5 lacs per month.
                        </div>
                        <div className="warehouseeImg">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_205.png`} alt="" />
                        </div>
                    </div>
                    <div className="warehouseeRight">
                        <WareHouseComp />
                    </div>
                </div>

                <div className="partner">
                    <div className="partnerTitle">
                        All about our <span style={{ color: "#00B050" }}>Partnership</span>
                    </div>
                    <div className="partnerItems">
                        <div className="partnerItem" id="partnerCostItem">
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_206.png`} alt="" />
                                </div>
                            </div>
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    How it <span style={{ color: "#00B050" }}>works?</span>
                                </div>
                                <div className="partnerRightDesc">
                                    It is a seamless and swift process where delivery personnel of franchisee partners pick up shipments from our allocated points and deliver them to the customer's doorstep.
                                </div>
                            </div>
                        </div>
                        <div className="partnerItem">
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    What <span style={{ color: "#00B050" }}>we do?</span>
                                </div>
                                <div className="partnerRightDesc">
                                    Send33 not only distributes the e-commerce load, generates revenue & provides training and monitors the performance of the delivery partners of franchisee holders, franchisees are also provided with a training program and given access to our Employee Resource Planning portal.
                                </div>
                            </div>
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_199.png`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="partnerItem" id="partnerCostItem">
                            <div className="partnerItemLeft">
                                <div className="partnerItemLeftImg">
                                    <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_197.png`} alt="" />
                                </div>
                            </div>
                            <div className="partnerItemRight">
                                <div className="partnerRightTitle">
                                    What costs are incurred by the franchisee?
                                </div>
                                <div className="partnerRightDesc">
                                    Send33 not only distributes the e-commerce load, generates revenue & provides training and monitors the performance of the delivery partners of franchisee holders, franchisees are also provided with a training program and given access to our Employee Resource Planning portal.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grow">
                    <div className="growContainer">
                        <div className="growLeft">
                            <div className="growLeftTitle">
                                Grow your business with Send33
                            </div>
                            <div className="growLeftDesc">
                                Channel your growth with India's leading Fulfilment and Delivery brand into your business with Send33 Partner Program.
                            </div>
                            <a href="/fulfill">
                                <button id="btn2" style={{ width: "200px", fontSize: "16px" }}>Become a Send33 Partner</button>
                            </a>
                        </div>
                        <div className="growRight">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/New_employee1.png`} alt="" />
                        </div>
                    </div>
                </div>

                <div className="howw">
                    <div className="howwHeader">How it works?</div>
                    <div className="howwContainer">
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_183.png`} alt="" />
                            <span className="howwTitle">Get registered with us</span>
                            <div className="howwDes">
                                Upload your PAN and AADHAR to get registered
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_184.png`} alt="" />
                            <span className="howwTitle">Complete training</span>
                            <div className="howwDes">
                                Get our training order from our nearest center. Deliver it and complete your training
                            </div>
                        </div>
                        <div className="howwItem">
                            <img src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/Group_185.png`} alt="" />
                            <span className="howwTitle">Start Earning</span>
                            <div className="howwDes">
                                Book a delivery slot of your choice to go online and get your first order. Get paid for every order.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="homeBrands">
                    <div className="brandTitle">Brands that trust <span style={{ color: "#00B050" }}>Send33</span></div>
                    <div className="brandLogos">
                        <ImageComp img={"image_28.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_30.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"BoiveadLogo.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"fandp.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"image_29.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                        <ImageComp img={"mm_1.png"} maxWidth={"220px"} width={"100%"} height={"auto"} />
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default Services;
