function ImageComp({ img, width, height, maxWidth, objectFit }) {
    return (
        <img
            src={`${process.env.REACT_APP_CLOUDINARY_IMAGES}/${img}`}
            alt=""
            style={{
                width: width,
                height: height ? height : "auto",
                maxWidth: maxWidth ? maxWidth : "",
                objectFit: objectFit ? objectFit : ""
            }}
        />
    )
}
export default ImageComp;
