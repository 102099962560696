import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

function PartnerMain() {
    return (
        <div className="">
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">
                    <AlertTitle>Welcome</AlertTitle>
                    Our Team will Contact you soon!!
                </Alert>
            </Stack>
        </div>
    )
}

export default PartnerMain;