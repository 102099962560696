import "./Sidebar.scss";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Menu } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const Sidebar = ({ setSideOpen }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const items = [
        getItem(<Link to="/partner">Home</Link>, 'Home', <HomeOutlined />),
        getItem('Partner Profile', 'Partner_Profile', <UserOutlined />, [
            getItem(<Link to="/partner/partner-profile">Profile</Link>, 'Partner-Profile'),
            getItem(<Link to="/partner/partner-docs">Partner Documents</Link>, 'Partner-Docs'),
        ]),
        getItem(
            <div onClick={handleClickOpen}>Logout</div>, 'Logout', <LogoutOutlined />),
    ];

    return (
        <>
            <div
                className="sidebar"
                style={{
                    width: 256
                }}
            >
                <div className="partner-side-top">
                    <Link to="/partner">
                        <img src="/img/logo.png" alt="" />
                    </Link>
                    <i
                        className="fa-solid fa-xmark"
                        id="partner-side-close"
                        onClick={() => { setSideOpen(false) }}
                        style={{ color: "white", fontSize: "2rem" }}
                    >
                    </i>
                </div>

                <Menu
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    theme="dark"
                    items={items}
                />
            </div>



            {/*  */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        sessionStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("user");
                        localStorage.removeItem("userDoc");
                        window.location.replace("/partner-login")
                        handleClose()
                    }}>
                        OK
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Sidebar;
