import "./Login.css";
import { useFormik } from "formik";
import { useState, useContext } from "react";
import * as Yup from "yup";
import axios from "axios";
import CustomAxios from "../../../CustomAxios/CustomAxios";

import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';
import { AuthContext } from "../../../context/AuthContext";

function Login() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const { dispatch } = useContext(AuthContext);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid Email").required("Required"),
            password: Yup.string().required("Required")
        }),

        onSubmit: async (values) => {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/login`, values)
                .then(resp => {
                    getDoc(resp.data.user);
                    sessionStorage.setItem("accessToken", resp.data.tokens.access.token);
                    localStorage.setItem("refreshToken", resp.data.tokens.refresh.token);
                }).catch(err => {
                    setErrorMessage(err.response.data.message);
                    setLoading(false);
                    setError(!error);
                })

            function getDoc(user) {
                CustomAxios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rider/extended-profile`)
                    .then(resp => {
                        localStorage.setItem("userDoc", JSON.stringify(resp.data));
                        dispatch({ type: "Login", payload: user })
                        setLoading(false);
                        navigate("/partner");
                    })
                    .catch(err => {
                        if (err.response.status === 404) {
                            navigate("/partner-verify");
                        }
                        setErrorMessage(err.response.data.message);
                        setLoading(false);
                    })
            }

        }
    });

    return (
        <form onSubmit={formik.handleSubmit} >
            <div className="login">
                <div className="login-container">
                    <div className="loginTop">
                        <span className="LoginHeader"><span style={{ color: "#00B050" }}>Rider</span> Login</span>
                    </div>
                    <div className="loginBottom">
                        <div className="loginEmail">
                            <span className="InptTitle">Email</span>
                            <input type="email" name="email" onChange={formik.handleChange} />
                        </div>
                        <div className="loginEmail">
                            <span className="InptTitle">Password</span>
                            <input type="password" name="password" autoComplete="true" onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className="login-btns">
                        <button type="submit" className="loginBtn" id="btn4" style={{ margin: "0 8px" }}>Login</button>
                        <a href="/partner-register">
                            <span className="loginBtn" id="btn5">Register</span>
                        </a>
                    </div>
                    {errorMessage && <span style={{ margin: "0 1rem", color: "red" }}>{errorMessage}</span>}
                    {loading && <span style={{ margin: "0 1rem" }}><Spin /></span>}
                </div>
            </div>
        </form>

    )
}

export default Login;
