import "./AdminSidebar.scss";
import AdminDropDown from "./AdminDropDown"
import { useState } from "react";
import { Modal } from 'antd';
import { Link } from "react-router-dom";

function AdminSidebar() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);

    const handleOk = (e) => {
        e.preventDefault()
        sessionStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        window.location.replace("/login")
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="admin-sidebar">
            <div className="admin-side-top">
                <Link to="/admin">
                    <img src="/img/logo.png" alt="" />
                </Link>
            </div>
            <div className="admin-side-center">
                <div>
                    <AdminDropDown
                        title={"BUSINESS"}
                        options={[
                            { option: "Fulfill and Deliver", link: "fulfill" },
                            { option: "Own Delivery Fleet", link: "delivery-fleet" },
                        ]}
                    />
                    <AdminDropDown
                        title={"EARN"}
                        options={[
                            { option: "Delivery Partner", link: "delivery-partner" },
                            { option: "Warehouse", link: "warehouse" },
                        ]}
                    />
                    <AdminDropDown
                        title={"Jobs"}
                        options={[
                            { option: "Create Job", link: "create-job" },
                        ]}
                    />
                    <AdminDropDown
                        title={"Partner"}
                        options={[
                            { option: "Partner Documents", link: "partner-upload-docs" },
                        ]}
                    />
                </div>
            </div>
            <div className="admin-side-bottom">
                <div className="dash-logout" >
                    <button onClick={showModal} >
                        Logout
                    </button>
                </div>
                <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    Are you sure you want to logout?
                </Modal>
            </div>
        </div>
    )
}

export default AdminSidebar;
