import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext"
import RiderLogin from "./Login"
import PartnerVerify from "../PartnerVerifDocs"

export default function LoggedIn() {
    const { user } = useContext(AuthContext);
    const userDoc = JSON.parse(localStorage.getItem("userDoc"))

    return user?.role === "rider" ? userDoc?.adhaar ? <Outlet /> : <PartnerVerify /> : <RiderLogin />
}
