import "./styles/Topbar.css"
import { Link } from "react-router-dom";
import { Dropdown } from 'antd';
import { useState } from "react";

const businessItems = [
    {
        label: <Link to="/fulfill">Fulfill and Deliver</Link>,
        key: '0',
    },
    {
        label: <Link to="/delivery-fleet">Own Delivery Fleet</Link>,
        key: '1',
    }
];

const earnItems = [
    {
        label: <Link to="/rider">Become Delivery Partner</Link>,
        key: '3',
    },
    {
        label: <Link to="/warehouse">Rent your Warehouse</Link>,
        key: '4',
    }
];

const dispWidth = window.innerWidth;

function Topbar() {

    const [btnClick, setBtnClick] = useState(dispWidth > 600 ? true : false);

    return (
        <div className="topbar" id="home-top">
            <div className="topbarLeft">
                <Link to="/">
                    <img src="./img/logo.png" alt="" />
                </Link>
                <i id="topOptn" className="fa-solid fa-bars" onClick={() => setBtnClick(!btnClick)}></i>
            </div>
            <div className="topbarRight" style={btnClick ? { display: "flex" } : { display: "none" }}>
                <div className="topbarOption">
                    <Link to="/about">
                        <span className="topbarOptionTitle">About us</span>
                    </Link>
                </div>
                <div className="topbarOption">
                    <Link to="/services">
                        <span className="topbarOptionTitle">Services</span>
                    </Link>
                </div>
                <div className="topbarOption">
                    <Dropdown
                        menu={{
                            items: businessItems,
                        }}
                        trigger={['click']}
                    >
                        <div className="">
                            <span className="topbarOptionTitle">Business</span>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </Dropdown>
                </div>
                <div className="topbarOption">
                    <Dropdown
                        menu={{
                            items: earnItems,
                        }}
                        trigger={['click']}
                    >
                        <div className="">
                            <span className="topbarOptionTitle">Earn</span>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </Dropdown>

                </div>
                <a href="/partner">
                    <button className="topbarOptionBtn" id="btn">Login Now</button>
                </a>
            </div>
        </div>
    )
}

export default Topbar;